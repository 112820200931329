.paypal-main {
    .order-summary-venmo {
        margin-top: 90px;
        padding: 20px 57px;
        background: #ffffff;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 10px;

        position: relative;
        $border: 1.5px;
        color: #FFF;
        background: #2b2326;
        background-clip: padding-box;
        border: solid $border transparent;
        border-radius: 30px;

    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            /* !importanté */
            border-radius: inherit;
            /* !importanté */
            background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
        }
        .payHeading {
            border-bottom: 1px solid #efefef;

            h2 {
                font-weight: 700;
                font-style: normal;
                font-size: 24px;
                margin-bottom: 15px;
                color: #fff;
            }
            p {
                font-weight: 500;
                font-style: normal;
                font-size: 16px;
                color: #ff0083;
                margin-bottom: 20px;
            }
        }
        .paypal-due {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            p {
                font-weight: 600;
                font-style: normal;
                font-size: 18px;
                color: #fff;
            }
        }
    }
    .btnRow {
        margin-top: 30px;
    }
}
