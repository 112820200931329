.mail{
    padding-top: 56px;
    padding-bottom: 110px;
    position: relative;
    overflow: hidden;
    .myyyelipse{
        position: absolute;
        right: -467px;
        top: -578px;
        z-index: -1;
    }
    .myyyelipse2{
        position: absolute;
        left: -424px;
        z-index: -1;
    }
    .mail-box{
        padding: 50px;
      
        box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.04);
        border-radius: 20px;
        .backk{
            color: #FFF;
            position: absolute;
            top: 20px;
            left: 140px;
font-size: 14.715px;
font-style: normal;
font-weight: 600;
line-height: 16.818px; /* 114.286% */
display: flex;
padding: 12.613px 16.818px;
justify-content: center;
align-items: center;
gap: 12.613px;
border-radius: 94.599px;
border: 1.577px solid #5B0F36;
background: #151515;
        }
        .upper-heading{
            margin-bottom: 40px;
            h5{
                
                font-style: normal;
                font-weight: 700;
                font-size: 38px;
                line-height: 110%;
          
                color: #fff;
                margin-bottom: 16px;
                text-align: center;
            }
            p{
                font-style: normal;
               font-weight: 500;
                font-size: 18px;
                line-height: 130%;
                color: #A3A3A3;
                text-align: center;
            }
        }
        .bottom-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;
            .option-field{
                flex: 1 0 48%;
                margin-bottom: 20px;
               input{
                border-radius: 145px;
                border: 1px solid rgba(255, 255, 255, 0.03);
                background: rgba(255, 255, 255, 0.03);
                box-shadow: 0px 10.511px 52.555px 0px rgba(0, 0, 0, 0.05);
                padding: 16px 20px;
                color: #CFCFCF;
                width: 100%;
                &::placeholder{
                    color: #CFCFCF;
                }
               }
            }
        }
        .commonnn{
                button{
                    display: flex;
                    width: 304px;
                    padding: 23.124px 21.022px;
                    justify-content: center;
                    align-items: center;
                    gap: 10.511px;
                    border-radius: 56px;
background: #FF0083;
box-shadow: 0px 18.92px 31.533px -10.511px rgba(255, 0, 131, 0.15);
color: #FFF;
margin: 0 auto;
margin-top: 50px;
font-size: 16.818px;
font-style: normal;
font-weight: 600;
line-height: 100%; /* 16.818px */
text-transform: uppercase;
                }
        }
    }
}

@media (max-width:600px){
    .mail .mail-box{
        padding: 20px;
    }
    .mail .mail-box .upper-heading h5{
        font-size: 24px;
    }
    .mail .mail-box .upper-heading p{
        font-size: 16px;
    }
    .mail .mail-box .bottom-content .option-field{
        margin: 0;
    }
    .commonnn{
        margin-top: 20px;
    }
}