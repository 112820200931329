.cryptoMain-main {
    .cryptoHeading {
        h1 {
            font-weight: 600;
            font-style: normal;
            font-size: 35px !important;
            color: #ff0083;
        }
        p {
            font-weight: 500;
            font-style: normal;
            font-weight: 500;
            font-size: 20px !important;
            color: #888888;
            margin-top: 25px;
            margin-bottom: 30px;
        }
    }
    input{
        padding-right: 94px;
    }
    .accord-Parent {
        .parad {
            font-weight: 500;
            font-style: normal;
            font-weight: 500;
            font-size: 20px !important;
            color: #888888;
            margin-top: 25px;
            margin-bottom: 30px;
        }
        .accrodinDiv {
            .card {
                background: unset !important;
                border: 1px solid #FF0083 !important;
                border-radius: 8px !important;
                margin: 8px 0px !important;
                overflow: hidden;
                
                .card-body {
                    -webkit-box-flex: 1;
                    -ms-flex: 1 1 auto;
                    flex: 1 1 auto;
                    padding: 0rem  1.25rem 1.25rem 1.25rem;
                }
                .btn-link {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    text-decoration: none;
                    padding: 0px;
                    span:nth-child(1) {
                        p {
                            font-weight: 600;
                            font-style: normal;
                            font-size: 18px;
                            color: #fff;
                            margin-left: 10px;
                        }
                    }
                    span:nth-child(2) {
                        p {
                            font-weight: 600;
                            font-style: normal;
                            font-size: 18px;
                            line-height: 110%;
                            margin-right: 10px;
                            /* identical to box height, or 20px */

                            text-align: right;

                            color: #b1b1b1;
                        }
                    }
                    span {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
            }
            .card-body {
                .copyInput {
                    position: relative;
                    .absltImgcopy {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        position: absolute;
                        top: 13px;
                        right: 13px;
                        p {
                            font-weight: 600;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            color: #ff0083;
                            margin-left: 12px;
                        }
                    }
                }
                .qrDiv {
                    text-align: center;
                    margin-top: 50px;
                    p {
                        margin-top: 10px;
                        font-weight: 500;
                        font-style: normal;
                        font-size: 16px;
                        line-height: 30px;
                        color: #767676;
                    }
                }
            }
            .card-header:first-child {
                border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
                background: unset;
                border-bottom: 0px;
            }
            .card-header {
                padding: 24px 24px !important;
                margin-bottom: 0;
                background-color: rgba(0, 0, 0, 0.03);
                border-bottom: 1px solidrgba(0, 0, 0, 0.125);
            }
        }
    }
}
@media (max-width: 768px) {
    .accrodinDiv {
        .card-body {
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            padding: 0px 14px 14px 14px !important;
        }
    }
    .cryptoMain-main .accord-Parent .accrodinDiv .card-header {
        padding: 14px 14px !important;
    }
    .cryptoMain-main .accord-Parent .accrodinDiv .card .btn-link span:nth-child(1) p {
        font-size: 16px;
    }
    .cryptoMain-main .accord-Parent .accrodinDiv .card .btn-link span:nth-child(2) p {
        font-size: 16px;
    }
}
