.partner{
    padding-bottom: 110px;
    padding-top: 100px;
    .main-card{
        background: #FFFFFF;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        padding: 60px;


        position: relative;
        $border: 1.5px;
        color: #FFF;
        background: #2b2326;
        background-clip: padding-box;
        border: solid $border transparent;
        border-radius: 30px;

    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            /* !importanté */
            border-radius: inherit;
            /* !importanté */
            background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
        }
        .upper-heading{
            margin-bottom: 58px;
            h5{
                
                font-style: normal;
                font-weight: 700;
                font-size: 35px;
                line-height: 120%;
                text-align: center;
                color: #fff;
            }
        }
        .inner-card-parent{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 25px;
            padding-bottom: 52px;
            .inner-card{
                background: unset;
                border: 1px solid var(--Accent, #FF0083);
                box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
                border-radius: 10px;
                padding: 25px 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                h6{
                    
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 120%;
                    text-align: center;
                    text-transform: capitalize;
                    color: #fff;
                    margin-top: 24px;
                }
            }
            
        }
        .bottom-text{
            padding-top: 52px;
            border-top: 1px solid #FEDAEB;
            a{
                
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 120%;
                text-align: center;
                text-transform: uppercase;
                color: #FF0083;
                display: block;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

@media (max-width:991px){
    .partner .main-card .inner-card-parent{
        flex-direction: column;
    }
    .partner .main-card .inner-card-parent .inner-card{
        width: 100%;
    }
}

@media (max-width:600px){
    .partner .main-card{
        padding: 25px;
    }
}