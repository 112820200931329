.chekoutt-main {
    padding: 80px 0px;
    .checkMain {
        font-weight: 600;
        font-style: normal;
        font-weight: 600;
        font-size: 45px;
        color: #ff0083;
    }
    .payment-tabs {
        margin-top: 39px;
        .navlik-1{
            width: 185px;
        }
        .navlik-4{
            width: 221px;
        }
        .nav-pills .nav-link {
            
            background: rgba(255, 0, 131, 0.05);
            font-weight: 600;
            font-style: normal;
            font-size: 18px;
            color: #ff0083;
            padding: 12px 25px;
            margin-right: 15px;
            display: flex;
            align-items: center;
            border: 1px solid transparent;
            img{
                margin-right: 8px !important;
            }
        }
        .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
            border: 1px solid #FF0083
        }
    }
}
@media(max-width:601px){
    .chekoutt-main {
        padding: 40px 0px;
    }
}