.main-navbar {
  justify-content: center;
  align-items: center;
  padding: 26px 0;
  position: relative;
  z-index: 9;
  .navbar-collapse {
// margin-left: 120px !important;
  }
  .navbar-brand {
    display: inline-block;
    font-size: 1.25rem;
    white-space: nowrap;
  }

  // .common {
  //   color: #ff0083 !important;
  // }

  .navbar-nav {
    margin-left: 79px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    margin-top: 15px;
    padding: 15px 25px;


    &:hover {
      color: #ff0083 !important;
    }
  }

  .bg-color {
    background-color: #ff0083 !important;
  }

  .style-bar {
    background-color: #ff0083;
    border-radius: 4px;
  }

  .form-inline {
    a {
      color: black;
      padding: 0px 15px;
      display: inline-block;
    }

    .btn-common-nav {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      color: #ffffff;
      padding: 16px 40px;
      background: #ff0083;
      justify-content: center;
      align-items: center;
      border: 1px solid #ff0083;

      &:hover {
        background-color: #ffffff;
        color: #000000;
        border: 1px solid #ff0083;
      }
    }
  }

  .img200 {
    width: 34px;
    height: 34px;
    object-fit: cover;
    border-radius: 50%;
  }

  .wallet-modal {
    .modal-dialog {
      // margin: 16.75rem auto;
    }

    .modal.show .modal-dialog {
      max-width: 746px !important;
    }

    .modal-content {
      height: 343px;
      background: #ffffff;
      border: 1px solid #f1f1f1;
      box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
    }

    .modal-body {
      position: relative;

      .cross-img1 {
        position: absolute;
        top: 21px;
        right: 21px;
      }

      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 29px;
        margin-top: 29px;
        text-align: center;
        color: #121212;
      }
    }

    .button-modal1 {
      .modal-button {
        width: 373px;
        height: 182px;
        background: #ffffff;
        border-width: 1px 1px 1px 0px;
        border: 1px solid #e7e7e7;

        &:hover {
          background-color: #fff8fb;
        }

        h3 {
          font-weight: 600;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;

          margin: 0px;
          text-align: center;
          margin-top: 10px;
          margin-bottom: 8px;

          color: #000000;
        }

        p {
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 32px;
          font-weight: 400;
          text-align: center;

          color: #acacac;
        }
      }
    }

    .text-center {
      font-weight: 500;
      font-size: 14px;
      font-weight: 600;
      text-align: center;

      color: #acacac;

      span {
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #ff0083;
        font-weight: 600;
      }
    }
  }

  .login-btn {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #ff0083 !important;
    border-radius: 5px;
    transition: 0.7s;
    cursor: pointer;
    // &:hover{
    //   background: #FF0083;
    //   box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
    //   border-radius: 5px;
    //   padding: 16px 35px;
    //   font-style: normal;
    //   font-weight: 600;
    //   font-size: 16px;
    //   line-height: 100%;
    //   color: #FFFFFF !important;
    // }
  }

  .signup-btn {
    background: #ff0083;
    box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
    border-radius: 5px;
    padding: 16px 35px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff !important;
    margin-left: 25px;
    cursor: pointer;
  }
}

@media (max-width: 430px) {
  .button-modal1 {
    flex-direction: column !important;
    display: flex !important;
  }

  .main-navbar .wallet-modal .modal-content {
    width: 377px !important;
    height: 501px !important;
    background: #ffffff !important;
  }
}

@media (max-width: 360px) {
  .navbar-brand {
    width: 67%;
  }

  .main-navbar .style-bar {
    width: 28px;
  }
}

.navbar {
  position: relative;

  .ahsbahsba {
    position: absolute;
    height: 100%;
    left: 50%;
    top: 0px;
    transform: translateX(-50%);

    img {
      transition: transform 0.6s ease 0s;
      width: 146px;
      transform: scale(1) translateY(92px);
    }
  }
}

.main-navbar .navbar-nav .dropdown .dropdown-menu li a:hover {
  color: #000;
  border-radius: 8px;
}

.ashvshsv {
  text-decoration: none !important;
  border: none !important;
}

.signxs {
  font-style: normal;
  font-size: 16px;
  line-height: 100%;
  color: #ff0083;
  background-color: transparent;
  outline: none !important;
  box-shadow: none !important;
  cursor: pointer !important;
  border: none !important;
}

.main-navbar .navbar-nav .dropdown .dropdown-menu li a {
  border-radius: 8px;
}

.button-hedaer {
  transition: 0.4s ease-in-out;
  background: #ff0083;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 194px;
  height: 48px;
  box-shadow: 0px 18px 30px -10px #ff008326;
  border-radius: 5px;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #ff0083;
  font-weight: 600;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  cursor: pointer;
  margin-left: 20px;

}

.main-navbar {
  border-bottom: 1px solid transparent;
  z-index: 55;
  padding-left: 0px;
  transition: height 0.2s ease 0s;
  background: #fafafa;
  width: 100%;

  .navbar-nav {
    .nav-link {
      transition: 0.4s ease-in-out;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 110%;
      padding: 0.5rem 0px;
      color: #9d9d9d;
      margin-right: 40px;
      border: 2px solid transparent;

      &:active {
        color: #000;
      }

      &:hover {
        font-style: normal;
        color: #000000;
        font-size: 16px;
        line-height: 110%;
        border-bottom: 2px solid #ff0083;
      }
    }

    .nav-links {
      display: block;
      padding: 0.5rem 0px;
      font-style: normal;
      text-decoration: none !important;
      color: #000000;
      font-size: 16px;
      line-height: 110%;
      margin-right: 40px;
      border-bottom: 2px solid #ff0083;
    }
  }

  .btn-1 {
    margin-right: 25px;
    background: transparent !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #ff0083;
    cursor: pointer;
  }

  .btn-2 {
    cursor: pointer;
  }

  .hbhcdbh {
    padding-left: 11px;
  }
}

.togg i {
  color: #000000 !important;
}

.main-navbar .nav-item {
  margin-bottom: 8px !important;

  a {
    color: #0E1120;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */

  
  }
}

.main-navbar-mobile {
  transition: 0.4s ease-in-out;
  width: 100%;
  // position: fixed;
  transition: height 0.2s ease 0s;
  border-bottom: none;

  .navbar-nav .dropdown .dropdown-menu li a {
    border-radius: 8px;
  }

  .ahsbahsba {
    img {
      transform: scale(0.8) translateY(45px);
    }
  }

  background: transparent;

  .nav-item {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    margin: 0px !important;
    align-items: center;
    justify-content: center;
    align-content: center;
    color: gray;
  }

  .navbar-nav {
    .nav-link {
      transition: 0.4s ease-in-out;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 110%;
      padding: 0.5rem 0px;
      color: #9d9d9d;
      margin-right: 40px;
      border: 2px solid transparent;

      &:active {
        color: #000;
      }

      &:hover {
        font-style: normal;
        color: #000000;
        font-size: 16px;
        line-height: 110%;
        border-bottom: 2px solid #ff0083;
      }
    }

    .nav-links {
      transition: 0.4s ease-in-out;
      display: block;
      padding: 0.5rem 0px;
      font-style: normal;
      text-decoration: none !important;
      color: #000000;
      font-size: 16px;
      line-height: 110%;
      margin-right: 40px;
      border-bottom: 2px solid #ff0083;
    }
  }

  .btn-1 {
    margin-right: 25px;
    background: transparent !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #ff0083;
    cursor: pointer;
  }

  .btn-2 {
    cursor: pointer;
  }

  .hbhcdbh {
    padding-left: 11px;
  }
}

.navbar-toggler {
  outline: none !important;

  .togg {
    i {
      color: #ff0083 !important;
    }
  }
}

.refer-modal {
  .modal-dialog {
    max-width: 558px;
  }

  .modal-content {
    border-radius: 15px;
    background: var(--frame-color, #E7F2FF);
    backdrop-filter: blur(92px);
    border: unset;
  }

  .modal-header {
    border: none;
    justify-content: center;
    padding: 30px;

    .modal-title {
      color: var(--primary-color, #0E1120);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 20px */
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .clossebuttons{
        cursor: pointer;
      }
    }

    .btn-close {
      position: absolute;
      top: 18px;
      right: 15px;
      opacity: 1;
      background: url("../../../Assests/entypo_cross.svg") !important;
      border: none;
      padding: 9px;
      z-index: 999;
      background-repeat: no-repeat !important;
      padding: 0px;
      margin: 0px;
      background-position: center;
      background-size: cover !important;
      width: 28px;
      height: 28px;
    }
  }

  .modal-body {
    padding: 30px;
    padding-top: 0px;

    p {
      color: var(--primary-color, #0E1120);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 16px */
      margin-bottom: 32px;
    }

    .option-field {
      margin-bottom: 35px;

      label {
        color: var(--primary-color, #0E1120);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 18px */
        margin-bottom: 12px;
        display: block;
        text-align: start;
      }

      input {
        border-radius: 15px;
background: var(--text-feild-color, #DFEAFC);
        padding: 18px 20px;
        border: unset;
        font-style: normal;
        font-weight: 500;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: var(--primary-color, #0E1120)
        !important;
        width: 100%;
      }
      ::placeholder{
        color: var(--text-placeholder, #6E8491);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */
      }
    }

    .btn-submit {
      border-radius: 15px;
      background: var(--button-color, #222D37);
      border: none;
      padding: 18px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      color: #ffffff;
      width: 100%;
      &:hover{
        background: #FF0083;
      }
    }
  }
}
.buttton_svggneww{
  border-radius: 15px;
background: #DFEAFC;
display: flex;
align-items: center;
justify-content: center;
}
@media (max-width: 2560px) {
  .sdvgdvsdhvd {
    display: none;
  }
}

@media (max-width: 1199px) {
  .main-navbar {
    padding-bottom: 40px;
  }
  .navbar {
    background: unset !important;
    padding: 12px 0px !important;
  }

  .main-navbar-mobile .navbar-nav .nav-link:hover {
    border: none !important;
  }

  .navbar-collapse {
    background-color: #fafafa;
    border-radius: 18px;
    padding-bottom: 30px;
  }

  .main-navbar {
    height: unset;
    padding-top: unset;
  }

  .main-navbar .navbar-nav .nav-links {
    border-bottom: none;
    text-align: center;
    margin-right: 0px;
  }

  .navbar-nav {
    margin-top: 30px;
  }

  .main-navbar-mobile .navbar-nav .nav-links {
    border-bottom: none;
    text-align: center;
    margin-right: 0px;
  }

  .main-navbar-mobile .navbar-nav .nav-link {
    text-align: center;
    margin-right: 0px;
    padding-bottom: 15px !important;
  }

  .navbar .ahsbahsba {
    display: none;
  }

  .button-hedaer {
    margin-left: 0px;
    margin-top: 20px;
  }

  .shdvs {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .main-navbar .sbvsx {
    display: flex;
    margin: 0 auto;
  }

  .main-navbar .navbar-nav .nav-link {
    text-align: center;
    margin-right: 0px;
  }

  .main-navbar .navbar-nav .nav-item.active .nav-link::before {
    content: "";
    bottom: 0px;
    right: 0;
    left: 0;
    position: absolute !important;
    width: 14% !important;
    margin: 0 auto;
    height: 3px !important;
  }

  .main-navbar {
    .form-inline {
      justify-content: center;
    }
  }

  .main-navbar .navbar-nav {
    align-items: center;
    margin: 0 auto;
    margin-top: 20px;
  }

  .main-navbar .button-hedaer {
    margin: unset !important;
    margin-bottom: 15px;
  }

  .main-navbar-mobile .nav-item {
    margin: 0 !important;
    margin-bottom: 15px !important;
  }

  .nav-drop .dropdown-menu.show {
    left: unset !important;
    right: 340px !important;
  }
}

@media (max-width: 1199px) {
  .main-navbar .login-btn {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .main-navbar .signup-btn {
    display: block;
    margin: 0 auto;
    margin-top: 12px;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .navbar-nav {
    margin-top: 30px;
  }

  .main-navbar-mobile .navbar-nav .nav-links {
    border-bottom: none;
    text-align: center;
    margin-right: 0px;
  }

  .main-navbar-mobile .navbar-nav .nav-link {
    text-align: center;
    margin-right: 0px;
  }

  .main-navbar .navbar-nav .nav-links {
    border-bottom: none;
    text-align: center;
    margin-right: 0px;
  }

  .main-navbar {
    .formm-mm {
      .input-bbb {
        margin-left: 0px;
      }
    }

    .form-inline {
      justify-content: center;
    }
  }

  .main-navbar .navbar-nav .nav-link {
    text-align: center;
    margin-right: 0px;
    margin: 0 !important;
    padding-bottom: 15px !important;
  }

  .main-navbar .navbar-nav .nav-item.active .nav-link::before {
    content: "";
    bottom: 0px;
    right: 0;
    left: 0;
    position: absolute !important;
    width: 14% !important;
    margin: 0 auto;
    height: 3px !important;
  }

  .main-navbar .navbar-nav .dropdown .dropdown-menu {
    position: unset;
  }

  .nav-drop .dropdown-menu.show {
    left: unset !important;
    right: 260px !important;
  }
}

@media (max-width: 600px) {
  .hbdsjbd {
    width: 167px;
  }

  .modal-body .main_text .bhvchvh {
    font-size: 43px !important;
  }

  .main-navbar .navbar-nav {
    align-items: center;
    margin: 0 auto;
    margin-top: 20px;
  }

  .main-navbar-mobile .nav-item {
    margin: 0;
    padding: 10px 0;
  }

  .main-navbar .button-hedaer {
    margin: 10px auto !important;
  }

  .main-navbar .wallet-modal .modal-content {
    // margin: 0 auto;
  }

  .main-navbar .wallet-modal .button-modal1 .modal-button {
    width: 100%;
  }

  // .main-navbar .wallet-modal .modal-body .cross-img1 {
  //   position: absolute;
  //   top: 15px;
  //   left: 335px;
  // }
  .main-navbar .wallet-modal .modal-content {
    width: 100% !important;
  }

  .nav-drop .dropdown-menu.show {
    left: unset !important;
    right: 25px !important;
  }

  .nav-drop .dropdown-menu.show .boxy {
    right: 138px !important;
  }

  .main-navbar .wallet-modal .modal-dialog {
    // margin: 11.75rem auto;
  }

  .main-navbar-mobile .nav-item {
    margin: 0 !important;
  }

  .refer-modal .modal-header {
    padding: 15px;
  }

  .refer-modal .modal-header .modal-title {
    font-size: 18px;
  }

  .refer-modal .modal-header .btn-close {
    top: 5px;
    right: 3px;
  }

  .refer-modal .modal-body p {
    font-size: 16px;
  }

  .refer-modal .modal-body {
    padding: 15px;
  }
}

.buttonsss {
  background-color: transparent;
  border: none;
}

@media (max-width: 400px) {
  .navbar-brand {
    img {
      width: 167px;
    }
  }
}

@media (max-width: 400px) {
  .modal-body .main_text .bhvchvh {
    font-size: 30px !important;
  }

  // .main-navbar .wallet-modal .modal-body .cross-img1 {
  //   position: absolute;
  //   top: 15px;
  //   left: 318px;
  // }
  .nav-drop .dropdown-menu.show {
    left: 30px !important;
    top: 0;
    right: 0px !important;
  }
}

@media (max-width: 360px) {
  .navbar-brand {
    img {
      width: 150px;
    }
  }

  .nav-drop .dropdown-menu.show {
    width: 256px !important;
  }

  .main-navbar .wallet-modal .modal-body .cross-img1 {
    left: 287px;
  }
}

.sbjdfbsj {
  display: none;
}

.nav-drop {
  .dropdown-menu.show {
    left: -82px;
    width: 320px;
    border: none;
    box-sizing: border-box;
    border-radius: 10px;
    top: 70px;
    box-shadow: 0px 1px 13px 1px #ccc;
    padding: 18px 10px;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 18px;
      color: #131524;
      margin-bottom: 15px;
    }

    .boxy {
      width: 20px;
      height: 20px;
      position: absolute;
      background-color: #fff;
      right: 5px;
      top: -25px;
      transform: rotate(44deg);
    }

    .list-inline {
      background: #fbfbfb;
      border: 1px solid #d1d8e8;
      box-sizing: border-box;
      margin: 10px 0;
      padding: 10px;
      border-radius: 10px;

      img {
        position: relative;
        bottom: 10px;
      }
    }
  }

  .text-truncate {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #adb2bd;
    opacity: 0.8;
    margin-right: 10px;
  }

  .common-text {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    color: #131524;

    span {
      color: #ff0083;
    }
  }

  .bottom-text {
    img {
      display: inline-block;
      margin-right: 6px;
      margin-bottom: 6px;
    }

    p {
      display: inline-block;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: #adb2bd;
      opacity: 0.8;

      &:hover {
        color: #ff0083;
      }
    }
  }
}

@media (max-width: 600px) {
  .nav-drop .dropdown-menu.show {
    left: 30px !important;
    top: 0 !important;
    right: 0px !important;
  }
}

.connectwalletmodal {
  .modal-dialog {
    max-width: 558px;

    .modal-content {
      background: #ffffff;
      border-radius: 5px;
    }

    .modal-body {
      .main-heading {
        position: relative;
        margin-top: 40px;
        padding: 40px 40;

        h3 {
     
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 30px;
          color: #171515;
          text-align: center;
        }

        .crossimg {
          position: absolute;
          top: -9px;
          right: 0px;
        }
      }

      .clear {
        text-align: center;

        button {
          background: #ffffff;
          border-radius: 15px;
          padding-top: 24px;
          line-height: 100%;
          text-align: center;
          border: none;
          cursor: pointer;
          // width: 449px;
          border-top: 1px solid #eaeaea;
          width: 100%;
          margin-bottom: 15px;
          border-radius: 0px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 100%;
          color: #ff0083;
        }
      }

      .Submitproposal {
        .iconimages {
          display: flex;
          margin-top: 50px;
          margin-bottom: 50px;
          justify-content: center;
          align-items: center;

          .leftimage {
            border-radius: 15px;
            //    width: 215px;
            //    height: 215px;
            width: 50%;
            text-align: center;
            border: 1.5px solid transparent;
            cursor: pointer;

            //  padding: 44px 61px;
            img {
              margin-top: 30px;
            }

            &:hover {
              border: 1.5px solid #ff0083;
              border-radius: 5px;
            }

            p {
         
              font-style: normal;
              font-weight: 400;
              font-size: 25px;
              line-height: 30px;
              color: #171515;
              padding-top: 5px;
              padding-bottom: 44px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .connectwalletmodal .modal-dialog .modal-body .clear button {
    white-space: nowrap;
    font-size: 14px;
    text-align: center;
  }

  .connectwalletmodal .modal-dialog .modal-body .Submitproposal .iconimages .leftimage p {
    font-size: 16px;
  }

  .connectwalletmodal .modal-dialog .modal-body .main-heading h3 {
    font-size: 22px;
  }
}
.profile-dropdown{
  display: flex;
  align-items: center;
  gap: 16px;
}

.profile-dropdown .dropdown-toggle {
  background: #fafafa !important;
  border: unset;
  border-radius: 5px;
  padding: 12px 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #ff0083;
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }

  &::after {
    display: none;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.profile-dropdown {
  .dropdown-menu {
    background-color: unset;
    padding: 0px;
    // width: 337px;
    left: unset;
    right: 0 !important;

    .header {
      padding: 16px;
      border-bottom: 1px solid #DCE7F3;
      background: #E0ECFA;
h1{
  color: #0E1120;
padding-bottom: 4px;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 100%; /* 20px */
}
      h6 {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 22px;
        color: #404040;
      }
    }

    .parent {
      display: flex;
      align-items: center;
      padding: 20px 0;

      .black path {
        fill: #000;
      }

      .left {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: 1px solid #ebebeb;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
      }

      .right {
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #7a798a;
        }

        h6 {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #404040;
        }
      }

      &:hover {
        .left {
          background-color: #ff0083;
        }

        .change-my-color path {
          fill: #fff;
        }
      }
    }

    .redeem {
      display: flex;
      align-items: center;
      border-top: 1.5px solid #ebebeb;
      border-bottom: 1.5px solid #ebebeb;
      padding: 20px 0;

      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: #404040;
      }
    }

    .bottom-content {

      .multi {
        display: flex;
        align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #DCE7F3;
  background: #E7F2FF;
  &:hover{
    background: #E0ECFA !important;
    transition: 0.1s;

  }



        h6 {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: #404040;
        }

        a {
          &:hover {
            // h6 {
            //   color: #ff0083;
            // }

            // .change-my-color path {
            //   fill: #ff0083;
            // }
          }
        }
      }
    }
  }
}
.multiitextual{
  gap: 12px;
}

.myyyelipseww22{
position: absolute;
width: 100%;
z-index: -1;
top: 0;
}
.disconnect_textt{
  color: #CF1A12 !important;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 16.8px */
}

.mLogWid {
  max-width: 150px;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-image: transparent !important;
  border: 1px solid #ff0083 !important;
  border-radius: 5px;
  color: #ff0083 !important;
}

@media (max-width: 1199px) {
  .profile-dropdown .dropdown-toggle {
    width: 100%;
    margin: 20px 0;
    justify-content: center;
  }

  .profile-dropdown .dropdown-menu {
    width: 100%;
  }
}

.disconnect-icon path {
  fill: #404040;
}

@media (max-width: 600px) {
  .mLogWid {
    max-width: 100px;
  }

  .navbarMOdal {
    .meta {
      display: none !important;
    }

    .iconimages .wallo {
      width: 100% !important;
    }
  }
}

.modal-Nav {
  z-index: 99909 !important;
}

.high {
  height: 48px !important;
}

.high1 {
  border: 1px solid #ff0083 !important;
  padding: 16px 30px !important;
  width: unset !important;
}

.errorTxt {
  padding-top: 6px !important;
  text-align: left !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 100% !important;
  color: #ff3d00 !important;
}

.btnDisable {
  cursor: not-allowed !important;
  background: #282828 !important;
}

.inpuDisable {
  border: 1px solid #ff3d00 !important;
}

.wcm-overlay {
  z-index: 99999999999 !important;
}

.wcm-active {
  pointer-events: auto;
  z-index: 999999999 !important;
}

// cart styling here................................
.cart-parent {
  position: relative;

  .cart-btn {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background: #D0DEEF !important;
    border: unset;
  }

  span {
    border-radius: 100px;
    border: 1.5px solid #141414;
    background: #ff0083;
    padding: 4px 6px;
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    font-style: normal;
    line-height: 100%;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 6px;
    right: 9px;
    pointer-events: none;
  }
}

.cart-modal {
  

  .modal-dialog {
    max-width: 483px;
  }

  .modal-content {
    border-radius: 15px;
    background: var(--frame-color, #E7F2FF);
    backdrop-filter: blur(92px);
    border: none;
    overflow: hidden;
  }

  .modal-header {
   
    border-bottom: none;

    .modal-title{
      color: var(--primary-color, #0E1120);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 20px */
      text-transform: capitalize;    }

    .btn-close {
      background: url("../../../Assests/crosssnew.png") !important;
      background-size: contain !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      border: none !important;
      width: 24px;
height: 24px;
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }

  .modal-body {
    padding: 30px 25px;
    padding-top: 10px;

    .cart-main-modal {
      .inner-first {
        border-radius: 15px;
background: #E0EBFC;
padding: 16px 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        h6 {
          color: var(--primary-color, #0E1120);
          font-size: 14.715px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 14.715px */
          text-transform: uppercase;
          display: flex;
          align-items: center;
          gap: 5px;

          span {
            border-radius: 100px;
            background: #ff0083;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            text-align: center;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
          }
        }

        a {
          color: #CF1A12;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 14px */
        }
      }

      .cart-items {
        .cart-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 14px 15px;
          border-radius: 15px;
          background: #DFEAFC;
          margin-bottom: 10px;

          .left {
            display: flex;
            align-items: center;
            gap: 12px;

            .inner-right {
              h6 {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 16px */
                margin-bottom: 12px;
              }

              p {
                color: #0DA518;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 18px */
                text-transform: capitalize;
                // max-width: 62px;
                width: 100%;
                padding: 4px 10px;
                border-radius: 30px;
              }

              .available {
                border-radius: 15px;
                background: #C7E7DD;
              }

              .unavailable {
                background: #ff3535;
              }
            }
          }

          .right {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            gap: 17px;

            .inner-text {
              p {
                color: var(--secondary-color, #67717D);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 14px */
                text-align: right;
              }

              h6 {
                color: #0E1120;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 16px */
              }
            }
          }
        }
      }

      .total-fee {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        // border: 1px solid var(--stroke, #DCE7F3);

        padding: 15px;

        .inner-text {
          p {
            color: var(--primary-color, #0E1120);
            font-size: 14.715px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 14.715px */
            text-transform: uppercase;
            margin-bottom: 8px;
          }

          h6 {
            color: var(--secondary-color, #67717D);
            
            font-size: 16.818px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 16.818px */
            text-transform: uppercase;
          }
        }
      }
    }

    .purchase-main-content {
      .top-heading {
        margin-bottom: 25px;

        h6 {
          color: #FFF;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
          text-transform: uppercase;
          margin-bottom: 10px;
        }

        p {
          color: #FFF;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
        }
      }

      .inner-mid {
        border-radius: 10px;
        border: 1.5px solid #282828;
        padding: 15px;

        .inner-cart {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 17px;

          .inner-left {
            display: flex;
            align-items: center;
            gap: 12px;

            h6 {
              color: #FFF;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 100%;
            }
          }

          .inner-right {
            p {
              color: #FFF;
              text-align: right;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 100%;
            }

            h6 {
              color: #FFF;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 100%;
            }
          }
        }
      }

      .total-fee {
        border-radius: 10px;
        border: 1px solid #282828;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        p {
          color: #FFF;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
          margin-bottom: 8px;
        }

        h6 {
          color: #FFF;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
          text-align: start;
        }
      }
    }

    .bottom-btn {
      margin-top: 25px;

      .btn-pink {
        border-radius: 12px;
background: #222D37;
        border: none;
        padding: 16px;
        width: 100%;
        color: #E8F2FF;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        padding: 20px 38px;

        font-weight: 400;
        line-height: 100%; /* 14px */
        &:hover{
          background: #FF0083;
        }
      }
    }
  }
  .domain-set-img {
    position: relative;
    .domain-img {
      width: 56px;
      height: 56px;
      overflow: hidden;
    }
    p {
      color: #fff;
      font-size: 4px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      position: absolute;
      bottom: 4px;
      left: 2px;
    }
  }
}

.ifitem-failed {
  border-radius: 10px;
  border: 1px solid #282828;
  padding: 12px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 9px;
  margin-bottom: 10px;
  margin-top: 25px;

  h6 {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 6px;
    text-align: start;
  }

  p {
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: start;
  }
}
