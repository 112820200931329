.main-billing{
  
    min-height: 88vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .main-heading{
        text-align: center;
        h2{
            font-style: normal;
            font-size: "Gsemibold";
            font-size: 28px;
            line-height: 100%;
            color: #ffff;
            margin-bottom: 30px ;
        }
        p{
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 100%;
           color:  #9D9D9D;
        }
        span{
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 100%;
            color: #FF0083;
        }
    }
}