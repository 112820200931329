.domain-search-result {
    position: relative;
    overflow: hidden;
    .topNav-top {
        border-bottom: 1px solid #282828;
        .topNavs {
            a {
                color: #9d9d9d;
                font-weight: 600;
                font-size: 16px;
            }
            .nav-link {
                display: block;
                padding: 29px 40px;
            }
        }
        .nav-pills .nav-link.active,
        .nav-pills .show > .nav-link {
            color: #ff0083;
            background-color: transparent;
            border-bottom: 2px solid #ff0083;
        }
        .nav-pills .nav-link {
            border-radius: 0;
        }
    }
}
.progress-sec {
    padding-top: 80px;

    .progress-barr {
            display: flex;
            justify-content: space-between;
            align-items: center;

            position: absolute;
            top: -15px;
            width: 100%;
        .item-bar {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 33%;
            .left {
                width: 100%;
                position: relative;
                left: -10px;
            }
            .center {
                text-align: center;
                width: 100%;
            }
            .right {
                text-align: right;
                width: 100%;
                position: relative;
                right: -10px;
                h6 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 100%;
                    color: #292929;
                    margin-bottom: 12px;
                }
                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    color: #292929;
                }
            }
        }
    }
    .progresss {
        .bg-success {
            background: linear-gradient(0deg, #36ab61, #36ab61), #bcbcbc !important;
        }
    }
    .btn-bar {
       
        width: 500px;
        margin: 0 auto;
        margin-top: 25px;
        button {
            border-radius: 12px;
            background: #222D37;
       
            padding: 16.818px 42.044px;
width: 100%;
            border: none;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: #ffffff;
            &:hover{
                background: var(--tomi_pink, #FF0083);
            }
        }
    }
}
.availbllle_data{
    padding: 30px;
    border-radius: 20px;
    border: 1px solid #DCE7F3;
    background: #E7F2FF;
    // width: 545px !important;
    height: 545px !important;
 
}
.top_avail_dataa{
    padding: 30px;
    border-radius: 20px;
    border: 1px solid #DCE7F3;
    background: #E7F2FF;
    margin: 0 auto;
    width: 605px;
    // height: 681px;
}
.new_domainsss_cvards{
    height: 504px !important;
    p{
        color: #000;
text-align: center;
font-size: 40.5px !important;
font-style: normal;
font-weight: 500 !important;
line-height: 34.617px; /* 85.473% */
    }
    span{
        color: #000;
text-align: center;

font-size: 40.5px !important;
font-style: normal;
font-weight: 500 !important;
line-height: 34.617px; /* 85.473% */
    }
}
.paramsProgres {
    margin-top: 52px;
    position: relative !important;
    .item-bar {
        h5 {
            font-size: 26px;
            color: #FFF;
            font-weight: 500;
        }
    }
    .item-bar .left {
        h5 {
            top: -40px;
            text-align: center;
            position: absolute;
            left: -40px;
        }
    }
    .item-bar .right {
        h5 {
            top: -40px;
            text-align: center;
            position: absolute;
            right: -50px;
        }
    }
}
.timor{
    /* padding-top: 20px; */
    top: 11px;
    position: relative;
}
@media (max-width: 1199px) {
    .paramsProgres .item-bar .left h5 {
        top: 0px;
        text-align: left;
        position: unset;
        left: -40px;
    }
    .paramsProgres .item-bar .right h5 {
        top: 0px;
        text-align: right;
        position: unset;
        left: -40px;
    }
}
@media (max-width: 991px) {
    // .progress-sec .progress-barr {
    //     flex-direction: column;
    //     justify-content: flex-start;
    //     align-items: flex-start;
    //     gap: 25px 0;
    // }
    // .progress-sec .progress-barr .item-bar {
    //     flex-direction: column;
    //     justify-content: flex-start;
    //     align-items: flex-start;
    //     gap: 15px 0;
    //     width: 100%;
    //     margin: 0 auto;
    // }
    // .progress-sec .progress-barr .item-bar .left{
    //     text-align: center;
    // }
    // .progress-sec .progress-barr .item-bar .right h6{
    //     text-align: center;
    // }
    // .progress-sec .progress-barr .item-bar .right p{
    //     text-align: center;
    // }
}
.pending {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #FFF;
}
.progress-bar {
    background-color: #ff0083;
    height: 25px !important;
    border-radius: 60px;
}
.totoParent {
    margin-top: 60px;
    position: relative !important;
}
.toto {
    height: 2px !important;
}
@media (max-width: 601px) {
    .domain-search .input-field .mysearchinput input {
        width: 100% !important;
    }
    .domain-search .input-field .btn-searchbar {
        position: absolute !important;
        top: 1px;
        right: 14px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 47px;
        width: 60px;
        height: 42px;
        border-radius: 12px;
        background: #222D37;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        border: transparent;
        color: #ffffff;
    }
    .domain-search .input-field .crosImg {
        position: absolute;
        right: 231px;
        top: 30px;
        display: none;
    }
    .domain-search .input-field input {
        width: 100%;
        height: 63px;
        border-radius: 10px;
        background: #333;
        box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding-left: 11px;
        outline: none;
        border: none;
    }
    .domain-search .input-field .input-icon {
        position: absolute;
        top: 29px;
        left: 20px;
    }
    .domain-search-result .topNav-top .topNavs .nav-link {
        display: block;
        padding: 23px 8px;
    }
    .domain-search-result .topNav-top .topNavs a {
        color: #9d9d9d;
        font-weight: 600;
        font-size: 14px;
    }
    .paramsProgres .item-bar h5 {
        font-size: 15px;
        line-height: 100%;
        color: #292929;
        font-weight: 500;
    }
    .progress-sec .btn-bar button {
       
        margin-bottom: 52px;
    }
    .progress-sec {
        padding-top: 0px;
    }
    .pending {
        margin-bottom: 52px;
    }
    .domain-search-result{
        overflow: hidden;
    }
    .domain-search .input-field input{
        padding-right: 100px;
    }
    .domain-search .availableDomain .domainMain p{
        overflow: auto;
    }
}
.placebid2-modal .modal-content .modal-body1 img {
    display: block;
    margin: 0 auto;
    margin-top: 4px !important;
}
.domain-search .availableDomain-trans .domainMain p {
    margin-right: 20px;
    color: #0E1120;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 17.6px */
    text-align: center;
}

