.transaction-main {
    .trans {
        font-weight: 600;
        font-style: normal;
        font-size: 45px;
        color: #ff0083;
        margin-top: 50px;
    }
    .selectDiv{
        position: relative;
        .pAbsolute{
            position: absolute;
            top: 0px;
            right: 25px;
            top: 30px;
        }
    }
    .aboutTransaction {
        padding: 21px 18px;
        background: #f2f4f9;
        border: 1px solid #efefef;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        font-weight: 400;
        font-style: normal;
        font-size: 22px;
        line-height: 100%;
        color: #292d32;
        outline: none;
        h4 {
            font-weight: 400;
            font-style: normal;
            font-size: 22px;
            line-height: 100%;
            color: #292d32;
        }
    }
    .allCata-search {
        background: #ffffff;
        border: 1px solid #efefef;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 19px;
margin-top: 20px;
        .pDiv {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p:nth-child(2n + 2) {
                padding: 0px 40px;
            }
            p {
                font-weight: 500;
                font-style: normal;
                font-size: 18px;
                color: #292d32;
                &:hover{
                    color: #ff0083;
                    cursor: pointer;
                }
            }
        }
    }
    .input-field {
        position: relative;
        width: 100%;
        margin: 0 auto;
        margin-top: 20px;
        .crosImg {
            position: absolute;
            right: 231px;
            top: 30px;
        }
        input {
            width: 100%;
            height: 84px;
            background: #ffffff;
            border: 1px solid #efefef;
            box-shadow: 0px 10px 50px #0000000d;
            border-radius: 10px;
            padding-left: 20px;
            outline: none;
            font-weight: 700;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #292929;
            &::placeholder {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 100%;
                color: #e1e1e1;
            }
        }
        .input-icon {
            position: absolute;
            top: 29px;
            left: 20px;
        }
        .btn-searchbar {
            position: absolute !important;
            top: 11px;
            right: 14px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 20px 60px;
            width: 202px;
            height: 60px;
            background: #ff0083;
            border-radius: 5px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            border: transparent;
            color: #ffffff;
        }
    }
    .domain-pending {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        border: 1px solid #efefef;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        margin: 20px 0px;
        span {
            padding: 16px 20px;
            h6 {
                font-weight: 400;
                font-style: normal;
                font-size: 18px;
                color: #292929;
            }
            p {
                margin-top: 5px;
                font-weight: 600;
                font-style: normal;
                font-size: 16px;
                color: #ff0083;
            }
        }
    }
    select {
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
      
    }
    option{
        padding: 40px;
    }
}
@media (max-width: 601px) {
    .transaction-main .input-field .crosImg {
        position: absolute;
        right: 231px;
        top: 30px;
        display: none;
    }
    .transaction-main .input-field input {
        width: 100%;
        height: 63px;
        background: #ffffff;
        border: 1px solid #efefef;
        box-shadow: 0px 10px 50px #0000000d;
        border-radius: 10px;
        padding-left: 11px;
        outline: none;
    }
    .transaction-main .input-field .input-icon {
        position: absolute;
        top: 29px;
        left: 20px;
        display: none;
    }
    .transaction-main .input-field .btn-searchbar {
        position: absolute !important;
        top: 11px;
        right: 14px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 47px;
        width: 60px;
        height: 42px;
        background: #ff0083;
        border-radius: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        border: transparent;
        color: #ffffff;
    }
    .transaction-main .input-field input {
        font-weight: 500;
        font-size: 12px;
    }
    .transaction-main .domain-pending {
        span {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: flex-start;
        }
        span:nth-child(1) {
            order: 3;
        }
        span:nth-child(2) {
            order: 2;
        }
        span:nth-child(3) {
            order: 1;
        }
    }
    .transaction-main .allCata-search .pDiv p:nth-child(2n + 2) {
        padding: 0px 14px;
    }
    .transaction-main .allCata-search .pDiv p {
        font-size: 15px;
    }
    .transaction-main .allCata-search {
        padding: 12px;
    }
}
