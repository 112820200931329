.main-place {
    padding-bottom: 110px;
    min-height: 100vh;

    overflow: hidden;
    .prize_div {

        position: absolute;
        bottom: 50px;
        /* Distance from the bottom of the page */
        left: 34px;

        h2 {
            color: #9D9D9D;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 20px */
        }

        .innner_prize {
            display: flex;
            align-items: center;
            gap: 5px;

            h3 {
                color: #000;
 padding-top: 6px;
                font-size: 40.5px;
                font-style: normal;
                font-weight: 500;
                line-height: 34.617px; /* 85.473% */
            }
        }
    }

.bittingpills{
    display: flex
    ;
        flex-direction: column;
        justify-content: space-between;
        /* align-items: center; */
        height: 100%;
        width: 100%;
}
    button.btn-back {
        border-radius: 12px;
background: #E8F2FF;
border: unset;
width: 124px;
gap: 6px;
justify-content: center;
        align-items: center;
        padding: 20px 10px;
        color: var(--primary-color, #0E1120);
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 110%; /* 13.2px */
text-transform: capitalize;
        margin-top: 40px;
        margin-bottom: 45px;
        display: flex;
 

        img.img-fluid {
            padding-right: 0px;
        }
    }

    .maincard {
        //  background-image: url(../../Assests/domaincard.svg) !important;
        // background-repeat: no-repeat !important;
        // background-size: cover !important;
        // /* Ensures the image covers the entire container */
        // background-position: center !important;
        /* Centers the image */
        width: 100%;
        // margin-top: 30px;
        // margin-bottom: 100px ;
        height: 234px;
        border-radius: 20px;
   
        box-sizing: border-box;

   
        background-size: cover;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        padding: 99.156px 97.292px 256.125px 96.375px;

        border-radius: 20px;
        height: 0;
        width: 100%;
        padding-bottom: 100%;
        border-radius: 21.429px;
        border: 1.429px solid #DCE7F3;
        background: #E0ECFA;
        .cardimgg {
            text-align: center;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 128.51px;
            top: 135.93px;
        }
        .cardinnerheading {
        border-radius: 21.429px;
border: 1.429px solid #DCE7F3;
background: #E0ECFA;


        }
        .cardds_divvv{
            padding: 44.156px 97.292px 256.125px 96.375px !important;
        }
        h3.cardinnerheading {
            color: #000;
            text-align: center;
            font-size: 40.5px;
            font-style: normal;
            font-weight: 500;
            line-height: 34.617px; /* 85.473% */
          border: unset;
          position: absolute;
          top: 247px;
          right: 219px;
        }
    }
    .tommmi_acrd{
        position: absolute;
        z-index: 999;
        top: 35px;
        right: 249px;
    }
    .mainCardtwo {
        background-image: unset;
        position: relative;
        background-color: #272727;
        border: 2px solid #ffdc93;
        overflow: hidden;
        height: 0;
        width: 100%;
        padding-bottom: 100%;
      

        .tomis {
            position: absolute;
            left: 27px;
            top: 27px;
        }

        .govern {
            position: absolute;
            right: 27px;
            top: 27px;
            width: 180px;
        }

        h3.cardinnerheading {
            color: #ffdc93;
        }
    }

    .bid-card {
        margin-top: 30px;

        .inner-content {
            h3 {
                color: var(--primary-color, #0E1120);
                font-size: 14.715px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 14.715px */
                text-transform: uppercase;
                margin-bottom: 8px;
            }

            p {
                color: var(--secondary-color, #67717D);
               
                font-size: 16.818px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 16.818px */
                text-transform: uppercase;
                margin-bottom: 38px;
            }

            h5 {
                color: var(--secondary-color, #67717D);
                
                font-size: 16.818px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 16.818px */
                text-transform: uppercase;
                margin-bottom: 8px;
            }
        }
    }

    .brdr {
        width: 100%;
        height: 0px;
        border: 1px solid #282828;
        margin-top: 15px;
    }

    .brdract {
        width: 100%;
        height: 0px;
        border: 1px solid #eceaea;
        margin-top: 15px;
        margin-bottom: 17px;
    }

    .heading-bid {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h5 {
            margin-bottom: 30px;
            color: var(--primary-color, #0E1120);
            font-size: 38px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 38px */
        }

        .btn-sharee {
            border-radius: 6px;
            border: 1px solid #FF0083;
            background: transparent;
            padding: 8px 10px;
            display: flex;
            align-items: center;
            gap: 5px;
            color: #FF0083;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
        }
    }

    .information {
        margin-top: 27px;

        .para {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            color: #777e90;
        }

        .avtars {
            margin-top: 29px;
            margin-bottom: 58px;
            display: flex;

            .avtarfirst {
                display: flex;

                ul.autmint {
                    margin-left: 10px;

                    .Minted {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 100%;
                        color: #777e90;
                        margin-bottom: 5px;
                    }

                    .Tim {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 100%;
                        color: #131524;
                    }
                }
            }
        }
    }

    .bidActivity {
        margin-top: 29px;

        .content-Activity {
            height: 230px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-track {
                background: #eeeeee;
                border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background: #ff0083;
                border-radius: 100px;
            }
        }

        .Activity-heads {
            margin-left: 15px;
        }

        .maaiinnoonneeact {
            display: flex;
            align-items: center;
        }

        .transferred {
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: #1d1d1d;
            margin-bottom: 3px;

            span {
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #ff0083;
                margin-left: 2px;
            }
        }

        .secransferred {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #777e90;

            span.fst {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #ff0083;
                margin-left: 2px;
            }

            span.sec {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #777e90;
                margin-left: 10px;
            }
        }
    }

    .bidhistory {
        overflow-y: scroll;
        height: 200px;
        margin-top: 30px;
        border-radius: 15px;
border: 1px solid var(--stroke, #DCE7F3);

        &::-webkit-scrollbar {
            width: 3px;
            height: 31px;
        }

        &::-webkit-scrollbar-track {
            background: #282828;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: #282828;
            border-radius: 100px;
            width: 3px;
            height: 31px;
        }

        &::-webkit-scrollbar-track-piece {
            height: 31px;
        }

        .content-history {
            display: flex;
            justify-content: space-between;
            padding-right: 17px;
        }

        ul.list-inline.maaiinnoonnee {
            display: flex;
        }

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: #1d1d1d;

            span {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #ff0083;
                padding-left: 8px;
            }
        }

        .hours {
            color: var(--primary-color, #0E1120);
            font-size: 14.715px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 14.715px */
            text-transform: uppercase;
        }

        img.img-fluid.sfgsdfsdsdgsdg {
            padding-right: 16px;
        }

        li.list-inline-item.shoqatali {
            text-align: center;

            h4.shoqii {
                color: var(--primary-color, #0E1120);
                
                font-size: 16.818px;
                font-style: normal;
                font-weight: 500;
                line-height: 21.022px; /* 125% */
            }

            p.sectime {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #777e90;
            }
        }
    }

    .lastcardbtn {
        // margin: auto;
        // position: fixed;
        // margin-top: 64px;

        .main-cardsbid {
            display: flex;
            align-items: center;
            flex-direction: column;

            .history-cards {
                width: 100%;
                height: auto;
                border-radius: 5px;
                border: 1px solid #282828;
                background: #111;
                border-radius: 10px;
                padding: 20px;
                // margin-right: 10px;
                margin-bottom: 20px;

                // &:last-child{
                //     margin-right: 0 !important;
                // }
                .history-content {
                    h5 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 100%;
                        text-transform: uppercase;
                        color: #FFF;
                    }

                    p {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 100%;
                        text-transform: uppercase;
                        color: #404040;
                        margin-top: 7px;
                    }

                    .history-inner {
                        p {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 100%;
                            text-transform: uppercase !important;
                            color: #FF0083;
                        }
                    }
                }
            }

            .history-cards2 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 20px;
                border-radius: 15px;
                border: 1px solid var(--stroke, #DCE7F3);
                padding: 20px;
                // margin-right: 10px;
                margin-bottom: 0px;

                // &:last-child{
                //     margin-right: 0 !important;
                // }


              
    
                .history-content {
                    h5 {
                        color: var(--primary-color, #0E1120);
                        font-size: 14.715px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%; /* 14.715px */
                        text-transform: uppercase;
                    }

                    .history-inner {
                        display: flex;
                        align-items: center;

                        p {
                            color: var(--secondary-color, #67717D);
                            text-align: right;
                            font-size: 14.715px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 21.022px; /* 142.857% */
                        }
                    }
                }
            }
        }

        .btnbtnbid {
            align-items: center;
            padding: 16px 40px;
            gap: 10px;
            width: 100%;
            height: 48px;
       
            background-color: transparent;
            // box-shadow: 0px 18px 30px -10px #ff008326;
            border-radius: 35px;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            border: none;
            color: #ff0083;
           
            border: 1px solid #ff0083;
            outline: none;

            &:hover {
                color: #ffffff;
                box-shadow: 0px 4px 4px #00000040;
                background: #ff0083;
            }
        }

        .withdraw-increase {
            display: flex;

            button {
                align-items: center;
                padding: 16px 40px;
                gap: 10px;
                width: 100%;
                height: 48px;
                background: #ff0083;
                box-shadow: 0px 18px 30px -10px #ff008326;
                border-radius: 5px;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                border: none;
                color: #ffffff;
                border: 1px solid #ff0083;
                outline: none;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    color: #ff0083;
                    // box-shadow: 0px 4px 4px #00000040;
                    background-color: transparent;
                }
            }
        }
    }

    .nav-tabs .nav-link {
        color: #222D37;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
        text-transform: capitalize;
        padding: 13px 40px !important;
        border-radius: 15px;
        background: #DFEAFC;
        border: none;

      
        text-align: start;
        justify-content: flex-start;
        align-items: center;
        align-content: start;
        &:hover{
            background: #BDCBDC;
        }
    }

    .nav-tabs .nav-link.active {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
        text-transform: capitalize;
        border-radius: 15px;
        border: unset !important;
background: #222D37;
    }

    .subscription-text {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #777e90;
        }

        h6 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            text-transform: uppercase;
            color: #1d1d1d;
        }
    }

    .mydomain-btns {
        display: flex;

        button {
            background: #ff0083;
            box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
            border-radius: 5px;
            padding: 16px 0;
            width: 100%;
            border: none;
            outline: none;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: #ffffff;
            margin-top: 40px;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .history-heads {
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            text-transform: uppercase;
            color: #FFF;
        }

        h3 {
            color: var(--secondary-color, #67717D);
            
            font-size: 13.818px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 16.818px */
            text-transform: uppercase;
            margin-top: 7px;
        }
    }
}
.mycardnewone{
    // margin-top: 45px !important;
}
.outeer_sidescards{

}
.divtabtext{
    position: relative;
}
.inner_sections_Datadiv{
    border-radius: 20px;
border: 1px solid #DCE7F3;
background: #E7F2FF;
padding: 30px;

}
.myyyelipseww{
position: absolute;
right: -482px;
top: -107px;
}
.myyyelipse2dd{
    position: absolute;
    left: -378px;
    top: 100px;

}
.placebid-modal {
    .modal-dialog {
        max-width: 465px;
    }

    .modal-content {
        width: 100%;
        padding: 0px 0px 30px;
        background: #111111;
        border-radius: 10px;
        position: relative;
        $border: 1.5px;
        color: #FFF;
        background: #2b2326;
        background-clip: padding-box;
        border: solid $border transparent;
        border-radius: 30px;

    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            /* !importanté */
            border-radius: inherit;
            /* !importanté */
            background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
        }

        .modal-header {
            background: unset;
            border-radius: 30px 30px 0px 0px;
            padding-bottom: 30px;
            padding-bottom: 0px;
            border: none;

            h3 {
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 100%;
                text-align: center !important;
                text-transform: uppercase;
                width: 100%;
                color: #FFF;
            }

            .close {
                span {
                    position: absolute;
                    top: 10px;
                    right: 30px;
                }
            }
        }

        .modal-body {
            padding-top: 0px;
            padding-left: 30px;
            padding-right: 30px;

            p{
                color: #FFF;
            }

            .btnmodalbtn {
                padding: 16px 40px;
                gap: 10px;
                margin-top: 26px;
                width: 100%;
                border: none;
                background: #ff0083;
                box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
                border-radius: 5px;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                color: #ffffff;
            }

            .input-field {
                position: relative;

                .upper-text {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 26px;

                    p {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        color: #FFF;
                        &:first-child {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            color: #FFF;
                        }
                    }

                    margin-bottom: 12px;
                }

                input {
                    border-radius: 12.255px;
                    background: #232323;
                    border: none;
                    outline: none;
                    color: #FFF;
                    border-radius: 25px;
                    width: 100%;
                    height: 50px;
                    padding: 14px;
                }

                .input-inner {
                    position: absolute;
                    top: 50px;
                    right: 30px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 140%;
                    color: #FFF;
                }
            }

            span {
                color: #ff0083;
            }

            .text-modalbid {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .head {
                    color: #FFF;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 130%;
                    /* or 31px */
                }

                .head1 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px !important;
                    line-height: 120%;
                    text-align: right;
                    color: #FFF;
                }

                .bidhead {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    color: #FFF;
                }

                .innertext-modalbid {
                    display: flex;
                    align-items: baseline;
                }
            }
        }
    }
}

.placebid2-modal {
    .modal-content {
        width: 100%;
        padding: 0px 0px 30px;
        background: #111111;
        border-radius: 10px;

        .modal-header {
            background: #111111;
            padding-bottom: 30px;
            border: none;

            h3 {
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 100%;
                text-align: center !important;
                // text-transform: uppercase;
                width: 100%;
                color: #fff;
            }

            .close {
                span {
                    position: absolute;
                    top: 10px;
                    right: 30px;
                }
            }
        }

        .modal-body {
            h6 {
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 100%;
                text-align: center;
                // text-transform: uppercase;
                color: #FFF;
            }

            img {
                display: block;
                margin: 0 auto;
                margin-top: 60px;
            }

            p {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 100%;
                text-align: center;
                // text-transform: uppercase;
                color: #FFF;
                margin-top: 21px;
            }

            .set-custom-weight {
                font-weight: 700;
            }

            button {
                background: #ff0083;
                box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
                border-radius: 5px;
                padding: 16px 0;
                width: 100%;
                border: none;
                outline: none;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                color: #ffffff;
                margin-top: 40px;
            }

            .withdraw-body {
                p {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 21px;
                    line-height: 130%;
                    text-align: center;
                    text-transform: uppercase;
                    color: #FFF;
                }

                .withdraw-btn {
                    display: flex;

                    button {
                        margin-right: 20px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

.setting-modal {
    .modal-dialog {
        max-width: 465px;
    }

    .modal-content {
        border-radius: 10px;

        .modal-header {
            background: #111111;
            position: relative;
            justify-content: center;

            button.close {
                background-color: transparent;
                position: absolute;
                top: 12px;
                right: 18px;
            }

            h6 {
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 100%;
                text-align: center;
                text-transform: uppercase;
                color: #FFF;
            }
        }

        .modal-body {
            padding: 30px;

            .upper-para {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                color: #FFF;
                margin-bottom: 10px;
            }

            .upper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: #ffffff;
                border: 1px solid #ffdbed;
                border-radius: 5px;
                padding: 10px;

                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 100%;
                    color: #FFF;
                }

                h6 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 130%;
                    color: #fff;
                    display: flex;

                    img {
                        width: 31px;
                        height: 31px;
                        object-fit: cover;
                    }
                }

                .mid {
                    padding: 0;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 140%;
                    color: #fff;
                }

                button {
                    background: transparent !important;
                    border: none;
                }
            }

            .mid {
                padding-top: 20px;
                padding-bottom: 27px;
                border-bottom: 2px solid #2D2D2D;

                .text {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .para1 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 170%;
                        color: #FFF;
                    }

                    .para2 {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 120%;
                        color: #FFF;
                    }
                }
            }

            .bottom {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 0;

                .para1 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 170%;
                    color: #FFF;
                }

                .para2 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 120%;
                    color: #FFF;
                }
            }

            .mint {
                background: #ff0083;
                box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
                border-radius: 5px;
                border: none;
                width: 100%;
                padding: 16px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                color: #ffffff;
            }
        }
    }
}

.main-place .bidhistory0 {
    //  overflow-y: unset;
    height: unset;
    margin-top: 30px;
    margin-bottom: -30px;
}

@media (max-width: 1024px) {
    .main-place .maincard {
        // height: 464px;
    }
}

@media (max-width: 1070px) {
    .main-place .bid-card .inner-content p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 170%;
        color: #fa2189;
        margin-bottom: 8px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    // .main-place .maincard {
    //     background: #fafafa;
    //     background-image: url('../../Assests/nftBg.svg');
    //     box-shadow: 0px 10px 50px #0000000d;
    //     border-radius: 10px;
    //     box-sizing: border-box;
    //     width: 100%;
    //     height: 534px;
    //     position: relative;
    // }
    .main-place .maincard h3.cardinnerheading {
        color: #fff;
        // position: absolute;
        // left: 54px;
        // top: 237px;
    }

    main-place .bid-card {
        padding: 24px;
        grid-gap: 8px;
        gap: 8px;
        /* width: 522px; */
        height: 180px;
        background: #ffffff;
        border: 1px solid #ffdbed;
        border-radius: 8px;
        margin-top: 30px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

@media (max-width: 911px) {
    .main-place .bid-card .inner-content p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 170%;
        color: #fa2189;
        margin-bottom: 8px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .main-place .bid-card {
        padding: 24px;
        grid-gap: 8px;
        gap: 8px;
        /* width: 522px; */
        height: 180px;
        background: #ffffff;
        border: 1px solid #ffdbed;
        border-radius: 8px;
        margin-top: 30px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    // .main-place .maincard {
    //     background: #fafafa;
    //     box-shadow: 0px 10px 50px #0000000d;
    //     border-radius: 10px;
    //     box-sizing: border-box;
    //     width: 100%;
    //     background-image: url('../../Assests/nftBg.svg');
    //     height: 534px;
    //     position: relative;
    // }
    .main-place .maincard h3.cardinnerheading {
        color: #ffffff;
        // position: absolute;
        // left: 54px;
        // top: 237px;
        // padding: 40px 0px;
    }
}

@media (max-width: 768px) {
    .main-place .maincard {
        // height: 342px;
    }

    .main-place .bidhistory {
        overflow-y: scroll;
        height: 100px;
        margin-top: 30px;
    }

    .main-place .maincard h3.cardinnerheading {

        color: #fff;
        // padding: 40px 0px;
    }

    .main-place .mainCardtwo .govern {
        position: absolute;
        right: 27px;
        top: 27px;
        width: 120px;
    }
}

@media (min-width: 600px) and (max-width: 768px) {
    .main-place .maincard {
        // height: 542px;
    }
    
}

@media (max-width: 600px) {
    .main-place .maincard h3.cardinnerheading {
        color: #000;
        text-align: center;
        font-size: 40.5px;
        font-style: normal;
        font-weight: 500;
        line-height: 34.617px;
        border: unset;
        position: absolute;
        top: 198px;
        right: 114px;
    }
    .main-place .tommmi_acrd {
        position: absolute;
        z-index: 999;
        top: 35px;
        right: 249px;
        top: 6%;
        left: 40%;
    }
    .history-cards2 {
        width: 100%;
        // height: auto;
        border-radius: 5px;
        // border: 1px solid #282828;
        // background: #111;
        padding: 20px;
        // margin-right: 10px;
        margin-bottom: 20px;
        height: 70px !important;

        // &:last-child{
        //     margin-right: 0 !important;
        // }
        .history-content {
            h5 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #FFF;
            }

            .history-inner {
                display: flex;
                align-items: center;

                p {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 100%;
                    text-transform: uppercase;
                    color: #FF0083;
                }
            }
        }
    }

    .main-place .bid-card .inner-content p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 170%;
        color: var(--secondary-color, #67717D);

        margin-bottom: 8px;

        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .main-place .maincard {
        // height: 392px;
    }

    .main-place .maincard h3.cardinnerheading {
        color: white;
        // padding: 20px 0px;
        word-break: break-word;
        // font-size: 30px;
        // position: absolute;
        // left: 54px;
        // top: 237px;
    }

    .main-place .bid-card {
        padding: 20px;
        border-radius: 15px;
        border: 1px solid var(--stroke, #DCE7F3);
        
background: #E7F2FF;
        grid-gap: 8px;
        gap: 8px;
        height: 180px;
        margin-top: 30px;
        width: 100%;
       
    }

    .main-place .bid-card {
        margin-bottom: 30px !important;
    }

    .main-place .maincard {
        margin-bottom: 45px;
    }

    .main-place .bidhistory .content-history {
        display: flex;
        justify-content: space-between;
        padding-right: 17px;
        flex-wrap: wrap;
        overflow-x: hidden;

        .fitcont {
            margin-bottom: 30px;
        }
    }

    .main-place .maincard {
        // height: 336px;
    }

    .main-place .mainCardtwo .govern {
        position: absolute;
        right: 17px;
        top: 17px;
    }

    .main-place .mainCardtwo .tomis {
        position: absolute;
        left: 17px;
        top: 17px;
    }

    .placebid-modal .modal-content .modal-body .input-field .upper-text p:first-child {
        font-size: 14px;
    }

    .placebid-modal .modal-content .modal-body .input-field .upper-text {
        margin-bottom: 5px;
    }

    .placebid-modal .modal-content .modal-body .input-field .upper-text p {
        font-size: 14px;

        span {
            font-size: 14px;
        }
    }

    .placebid-modal .modal-content .modal-body .input-field .input-inner {
        top: 40px;
        right: 20px;
    }
    .imoo{
        cursor: pointer;
        margin-left: 10px !important;
    }
}

@media (max-width: 380px) {
    .main-place .maincard h3.cardinnerheading {
        color: white;
        // padding: 20px 0px;
        word-break: break-word;
    }
}

.placebid-modal .modal-content .modal-body .text-modalbid .bidhead {
    margin-bottom: 5px;
}

.placebid-modal .modal-content {
    padding: 0px 0px 20px !important;
}

.auction-history {
    p {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 100%;
        text-transform: uppercase;
        color: #FFF;
        margin-top: 7px;
    }
}

.main-place .lastcardbtn .main-cardsbid .history-cards2 .history-content .history-inner {
    align-items: baseline;
    justify-content: flex-end;
}

.main-place .bidhistory .content-history {
    padding: 20px;
    border-bottom: 1px solid var(--stroke, #DCE7F3);
}

.placebid2-modal {
    .modal-dialog {
        max-width: 385px;
    }
}

.fSm {
    font-size: 13px;
    font-weight: bold;
}

.main-place .mainCardtwo h3.cardinnerheading {
    color: #ffdc93;
}

.main-place .mainCardtwo .govern {
    position: absolute;
    right: 4%;
    top: 2%;
    width: 45%;
}

.main-place .mainCardtwo .tomis {
    position: absolute;
    left: 4%;
    top: 5%;
    width: 21%;
}

.cardinnerheadingRelative {
    position: absolute;
    bottom: 10%;
}

@media (max-width: 2500px) {
    .main-place .maincard h3.cardinnerheading {
       
        // font-style: normal;
        // font-weight: 600;
        // font-size: 40px;
        // line-height: 100%;
        // text-align: center;
        // color: #fff;
        // padding: 0;
        // width: 90%;
        // word-break: break-all;
        // text-align: left;
        // position: absolute;
        // bottom: 4%;
        // left: 4%;
        // right: 20px;
    }
}

@media (max-width: 2000px) {
    .main-place .maincard h3.cardinnerheading {
        font-size: 32px;
    }
}

@media (max-width: 1600px) {
    .main-place .maincard h3.cardinnerheading {
        font-size: 32px;
    }
}

@media (max-width: 1500px) {
    .main-place .maincard h3.cardinnerheading {
        font-size: 32px;
    }
}

@media (max-width: 1300px) {
    .main-place .maincard h3.cardinnerheading {
        color: #000 !important;
        text-align: center !important;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 15.385px; /* 85.473% */
    }
    .main-place .prize_div .innner_prize h3 {
        color: #000;
        padding-top: 6px;
        font-size: 29.5px;
        font-style: normal;
        font-weight: 500;
        line-height: 34.617px;
        padding-bottom: 27px;
    }
}

@media (max-width: 950px) {
    .main-place .maincard h3.cardinnerheading {
        font-size: 2rem;
    }
}

@media (max-width: 767px) {
    .main-place .maincard h3.cardinnerheading {
        font-size: 32px;
    }
}

@media (max-width: 601px) {
    .main-place .maincard h3.cardinnerheading {
        font-size: 2rem;
    }
}

.alertWar {
    font-size: 14px;
}

.placebid2-modal .modal-content .modal-body2 img {
    display: block;
    margin: 0 auto;
    margin-top: 8px !important;
}

.placebid2-modal .modal-content .modal-body2 img {
    display: block;
    margin: 0 auto;
    margin-top: 8px !important;
}


.container-place {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

@media (max-width:600px) {
    .card-manage .img-main span {
        bottom: 30px !important;
    }

    .card-manage {
        box-shadow: none !important;
    }
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: #d3d3d3 !important;
    cursor: not-allowed !important;
}

.main-place .nav-tabs {
    justify-content: flex-start !important;
    gap: 15px;
}

.main-place .nav-tabs .nav-link {
    border-bottom: 2px solid transparent;
    justify-content: center;
    text-align: center;
    padding: 0;
    padding-bottom: 7px;
}

.main-place .nav-tabs .nav-link.active {
    border-bottom: 2px solid #FF0083;
}


@media (max-width: 1199px) {
    .main-place .lastcardbtn {
        margin-top: 10px !important;
    }
}

@media (max-width:600px) {
    .main-place .lastcardbtn {
        margin-top: 33px !important;
    }
}


.share-modal-parent {
    .modal {
        background: rgba(0, 0, 0, 0.60);
        backdrop-filter: blur(2.5px);
        border: unset !important;
        border-radius: 15px !important;
    }

    .modal-dialog {
        max-width: 407px;
    }

    .modal-content {
        border-radius: 15px !important;
        background: var(--frame-color, #E7F2FF);
        backdrop-filter: blur(92px);
        border: unset !important;
        overflow: hidden;
    }

    .modal-header {
        background: var(--frame-color, #E7F2FF);
        backdrop-filter: blur(92px);
        justify-content: center;
        padding: 16px 25px;
        border: none;

        .close {
            position: absolute;
            top: 16px;
            right: 25px;
            z-index: 9999;
        }

        .modal-title {
            color: var(--primary-color, #0E1120);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 20px */
            text-transform: capitalize;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    .modal-body {
        .parent-img-wrapper {
            position: relative;
            width: 227px;
            height: 227px;
            margin: 0 auto;

            h6 {
                color: #000;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 15.385px; /* 85.473% */
                position: absolute;
                bottom: 50%;
                left: 36%;

            }

        }

        .bottom-links {
            margin-top: 25px;
            margin-bottom: 10px;
            padding: 20px 98px;
            border-radius: 15px;
            border-radius: 15px;
            background: #E0EBFC;

            h6 {
                color: var(--primary-color, #0E1120);
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 12px */
                margin-bottom: 18px;
            }

            .inner-links {
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 130px;
                width: 100%;
                margin: 0 auto;
            }
        }

        .last-part {
            border-radius: 15px;
            background: #E0EBFC;
            padding: 16px 20px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            h6 {
                display: flex;
                align-items: center;
                gap: 10px;
                color: var(--primary-color, #0E1120);
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 14px */
              
            }
        }
    }
}

.bottom-links-set-new{
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1.5px solid #2D2D2D;
    background: #121212;
    padding: 20px;

    h6 {
        color: #FFF !important;
        text-align: center !important;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 600;
        line-height: 100% !important;
        /* 12px */
        text-transform: uppercase !important;
        margin-bottom: 18px !important;
    }

    .inner-links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 130px;
        width: 100%;
        margin: 0 auto;
        button{
            margin: 0 !important;
            width: unset !important;
        }
    }
}

.csghghscghcsgcsvsgcv{
    .modal-body .csgvtvscttysccstysc{
        color: #FFF !important;
        text-align: center !important;
        font-weight: 600;
        font-size: 18px !important;
        font-style: normal !important;
        line-height: 100% !important;
        text-transform: uppercase !important;
    }
    .modal-body .scsvscscttsftysdtyd{
        color: #FFF !important;
        text-align: center !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 500;
        line-height: 100% !important; 
    }
}