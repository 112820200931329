.myDomain {
    min-height: 100vh;
    overflow: hidden;

    .card-manage {
        position: relative;
        overflow: hidden;
        /* Add any necessary styling for the card */
    }

    .btn-manage {
        opacity: 0;
        /* Initially hidden */
        transition: opacity 0.3s ease;
        /* Smooth transition effect */
        position: absolute;
        /* Position it absolutely to control its position */
        bottom: 10px;
        /* Adjust this based on your layout */
        left: 10px;
        /* Adjust this based on your layout */
    }

    .card-manage:hover .btn-manage {
        opacity: 1;
        /* Make visible on hover */
    }

    .domainH {
        margin-top: 50px;
        margin-right: 15px;
        margin-left: 15px;

        .mydomainH {
            h2 {
                color: #0E1120;
                font-size: 56px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%;
                /* 61.6px */
            }

            font-weight: 400;
            font-style: normal;
            font-size: 45px;
            color: #ff0083;
            display: flex;
            align-items: center;

            span {
                // font-weight: 600;
                // font-style: normal;
                // font-size: 28px;
                // color: #ff0083;
                // background: rgba(255, 0, 131, 0.3);
                // padding: 6.5px 16px;
                // border-radius: 80px;
            }
        }
    }

    .dropdown span p {
        &:hover {
            color: #ff0083 !important;
            cursor: pointer;
        }
    }

    .domainSearh {
        background: #ffffff;
        border: 1px solid #efefef;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 18px;
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-top: 41px;

        .pspans {
            display: flex;
            align-items: center;
            position: relative;

            span {
                margin-right: 39px;
                display: flex;
                align-items: center;

                p {
                    font-weight: 500;
                    font-style: normal;
                    font-size: 18px;
                    color: #292d32;
                    margin-right: 13px;
                }
            }
        }

    }

    .mainnnndropppp {

        .righttt_drop {
            position: relative;

            input {
                border-radius: 39px;
                border: 1px solid #FF0083;
                background-color: unset;
                width: 527px;
                padding: 8px 8px 8px 24px;

            }

            button {}
        }

        .header_drop {
            display: flex;
            align-items: flex-start;
            gap: 31.533px;
        }

        .dropdown-toggle::after {
            display: none;
        }

        .show>.btn-success.dropdown-toggle {
            background-color: unset;
            border: unset;
            box-shadow: unset;
            outline: unset;
        }

        .btn-success:focus {
            box-shadow: unset;
            outline: unset;
            border: unset;
        }

        .btn-success {
            background-color: unset;
            border: unset;
            outline: unset;
            color: #FFF;
            display: flex;
            align-items: center;
            gap: 21.022px;
            font-size: 16.818px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            /* 16.818px */
        }

        .btn-success:not(:disabled):not(.disabled):active:focus,
        .show>.btn-success.dropdown-toggle:focus {
            box-shadow: unset;
            background-color: unset;
        }
    }


    .maindomain {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        h2 {
            color: #FFF !important;
            background-color: unset;
            font-size: 47.299px !important;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;

            /* 47.299px */
            span {
                color: #FF0083;

                font-size: 47.299px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
            }
        }

        button {
            border-radius: 37px;
            background: #FF0083;
            display: flex;
            z-index: -1;
            padding: 17.869px 33.066px;
            justify-content: center;
            align-items: center;
            gap: 10.511px;
            color: #FFF;
            font-size: 16.818px;
            font-style: normal;
            font-weight: 600;
            border: unset;
            line-height: 100%;
            /* 16.818px */
        }
    }

    .searchfeild {
        input {
            background: #ffffff;
            border: 1px solid #efefef;
            border-radius: 10px;
            padding: 18px;
            position: absolute;
            top: -17px;
            left: -1px;
            width: 100%;
            color: #000000;
            font-style: normal !important;
            font-weight: 400;
            font-size: 18px !important;
            line-height: 100% !important;

            &::placeholder {
                font-style: normal !important;
                font-weight: 400;
                font-size: 18px !important;
                line-height: 100% !important;

            }
        }

        span {
            position: absolute;
            top: 0;
            right: 14px;
            color: deeppink;
        }
    }

    .noDomainYet {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        h6 {
            font-weight: 400;
            font-style: normal;
            font-size: 22px;
            color: #ff0083;
        }

        button {
            margin-top: 20px;
            margin-bottom: 31px;
        }

        p {
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            color: #292d32;
        }
    }

    .nav-pills .nav-link {
        border-radius: 0rem;
    }

    .table-responsive {
        // margin-top: 30px;

        .navigation {
            margin-top: 20px;

        }

        .pagination {
            justify-content: center;

            .page-link {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 100%;
                color: #9D9D9D;
                border: none;
                background-color: transparent;

                &.active {
                    background-color: #ff0083;
                    border-radius: 5px;
                    color: #fff;
                }

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    .availableDomain {
        margin-top: 30px;
        margin-bottom: 40px;

        .row {
            padding: 15px 0px;
            // border-bottom: 1px solid #efefef;
        }

        // border: 1px solid #efefef;
        // filter: drop-shadow(0px 10px 50px rgba(0, 0, 0, 0.05));
        // border-radius: 10px;
        .domainMain {
            align-items: center;

            p {
                margin-right: 20px;
                font-size: 18px;

                span {
                    font-weight: 700;
                    font-style: normal;
                    color: #292929;
                }
            }
        }

        .availtick {
            background: #36ab61;
            border-radius: 45px;
            padding: 7.5px 13.5px;
            align-items: center;
            font-weight: 500;
            font-style: normal;
            font-size: 14px;
            color: #ffffff;

            img {
                margin-right: 4px;
            }
        }

        .domainRightHeartDiv {
            align-items: center;

            .imgPP {
                display: flex;
                align-items: center;
                margin-right: 100px;

                p:nth-child(2) {

                    font-weight: 500;
                    font-style: normal;
                    font-size: 16px;
                    color: #FF0083;
                }
            }

            p {
                font-weight: 400;
                font-style: normal;
                font-size: 16px;
                color: #5a5a5a;
            }

            .cartButton {
                background: #ff0083;
                border-radius: 5px;
                padding: 13px 21px;
                font-weight: 400;
                font-style: normal;
                font-size: 16px;
                color: #ffffff;
                outline: none !important;
                border: none;

                img {
                    margin-right: 12px;
                }
            }

            .hImg {
                margin-right: 17px;
            }
        }
    }

    .search-bar {
        background: #FAFAFA;
        border-radius: 5px;
        padding: 18px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #000;
        border: none;
        width: 380px;

        &::placeholder {
            color: #C9C9C9;
        }

    }
}

.myyyelipse {
    position: absolute;
    left: -494px;
    z-index: -1;
}

.myyyelipse2 {
    position: absolute;
    right: -154px;
    top: -302px;
    z-index: -1;

}

.img-btn {
    background: #ff0083;
    border-radius: 25px;
    padding: 13px 21px;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #ffffff;
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    img {
        margin-right: 12px;
    }
}

.img-btn-outline {
    color: #0E1120;
    font-size: 16.818px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 16.818px */
    padding: 13.664px 38.891px;
    border-radius: 15px;
    background: var(--secondary-button-color, #D0DEEF);
    border: unset;
    outline: none !important;
    gap: 10px;
    display: flex;
    align-items: center;
    &:hover {
        background: #BDCBDC;

     }
    img {
        margin-right: 12px;
    }
}

@media (max-width:1199px) {
    .myDomain .search-bar {
        width: 210px;
    }

    .myDomain .domainSearh .pspans span {
        margin-right: 16px;
    }

    .domain-search .inline-btns {
        display: flex;
        overflow: scroll;
        align-items: center;
        justify-content: center;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

@media (max-width:768px) {
    .myDomain .availableDomain .domainRightHeartDiv .imgPP {
        display: flex;
        align-items: center;
        margin-right: 33px;
    }
}

@media (max-width:601px) {
    .myDomain .availableDomain .domainRightHeartDiv {
        align-items: flex-start !important;
    }

    .myDomain .domainSearh .pspans {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .myDomain .domainSearh .pspans span :nth-child(2) {
        margin-right: 39px;
        display: flex;
        align-items: center;
        margin: 15px 0px;

    }

    .mydomainH {
        h2 {
            font-size: 21px !important;
            text-align: center;
        }

    }

    .btng {
        .img-btn {
            background: #ff0083;
            border-radius: 5px;
            padding: 8px 10px;
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            color: #ffffff;
            outline: none !important;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
        }
    }

    .myDomain .maindomain {
        flex-direction: column;
        gap: 50px;
        z-index: -1;
    }

    .myDomain .my-navs-one {
        justify-content: center;
    }

    // .myDomain .domainH .mydomainH span {
    //     font-weight: 400;
    //     font-style: normal;
    //     font-size: 18px;
    //     color: #ff0083;
    //     background: rgba(255, 0, 131, 0.3);
    //     padding: 5.5px 11px;
    //     border-radius: 80px;
    // }

    .myDomain .availableDomain .domainRightHeartDiv .imgPP {
        display: flex;
        align-items: center;
        margin-right: 33px;
    }

    .myDomain .availableDomain .domainMain {
        align-items: center;
        margin-bottom: 20px;
    }

    .btng .img-btn {
        white-space: nowrap;
    }

    .myDomain .searchfeild input {
        top: 21px;
        left: -1px;
        width: 100%;
        padding: 25px 18px;
    }

    .myDomain .searchfeild span {
        top: 38px;
    }

    .myDomain .availableDomain .availtick {
        padding-left: 0 !important;
    }

    .myDomain .domainSearh {
        background: transparent;
        border: none;
        box-shadow: none;
        flex-direction: column;
        padding: 0;
    }

    .myDomain .search-bar {
        width: 100% !important;
        background: #FFFFFF;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 5px;

    }

    .myDomain .domainSearh .sImg {
        position: absolute;
        top: 189px;
        right: 20px;
        width: 22px;
        height: 22px;
    }

    .myDomain .domainSearh .pspans {
        margin-bottom: 10px;
        justify-content: space-between;
    }

    .myDomain .domainSearh .pspans span {
        width: 48%;
        margin: 0 auto;
        justify-content: center;
        background: #FFFFFF;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        padding: 16px;
        margin-bottom: 10px;

    }

    .myDomain .domainSearh .pspans span p {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: #292D32;
    }

    .myDomain .my-navs-one {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .padd-sm {
        padding: 0;
    }

    .myDomain .domainH {
        margin-left: 0 !important;
    }
}

// @media (max-width:550px){
//     .myDomain .domainSearh .sImg {
//         position: absolute;
//         right: 42px;
//         width: 52px;
//         top: 32%;
//     }
// }

.myDomain {
    .my-navs-one {
        margin-top: 40px;
        margin-right: 15px;
        margin-left: 15px;

        gap: 12px;

        .nav-link {
            color: #222D37;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            /* 21px */
            text-transform: capitalize;
            padding: 13px 40px;
            border-radius: 15px;
            background: #DFEAFC;
            &:hover{
                background: #BDCBDC;
            }
        }

        .nav-link.active,
        .show>.nav-link {
            color: #FFF;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            /* 21px */
            text-transform: capitalize;
            border-radius: 15px;
            background: #222D37;
        }
    }
}


.dycydvcy {
    margin-top: 40px;
}

.goldenbg {
    background: linear-gradient(103deg, rgba(255, 160, 198, 0.15) 0%, rgba(126, 126, 126, 0.01) 110.97%), rgba(29, 29, 29, 0.20);

    backdrop-filter: blur(18.314544677734375px) !important;

    &:before {
        background: linear-gradient(103deg, rgba(255, 186, 2, 0.15) 0%, rgba(126, 126, 126, 0.01) 110.97%), rgba(29, 29, 29, 0.20) !important;
    }
}

.card-manage {
    padding: 20px 20px 0px;
    border-radius: 20px;
    // border: 1px solid #282828;
    height: 286px;
    // margin-bottom: 25px;
    transition: 0.3s linear;
    position: relative;
    $border: 1px;
    color: #FFF;
    border-radius: 15px;
    background: #E8F2FF;
    display: flex;
    align-items: center;


    .partnerimg {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: -1;
    }

    .newnftbg {
        position: absolute;
        right: 102px;
        /* bottom: 0; */
        top: 23px;

    }

    span {
        color: #000;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 15.385px;
        /* 85.473% */
        word-break: break-all;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .img-main {
        position: relative;
        overflow: hidden;




        p {
            color: var(--White, #FFF);
            padding-top: 111px;
            font-size: 23.31px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            /* 34.965px */
        }
    }

    .btn-manage {
        text-align: center;
        position: absolute;
        bottom: 16px;
        left: 0;
        right: 0;
        padding: 0px 20px;

        button {
            background: transparent !important;
            border: 1.5px solid #FF0083;
            border-radius: 35px;
            padding: 12px 25px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            width: 100%;
            line-height: 100%;
            color: #ff0083;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            margin-top: 20px;

            &:hover {
                background: unset;
                color: #fff;
                border: 1.5px solid #FF0083;
            }
        }
    }

    .btn-manage {
        text-align: center;
        position: absolute;
        bottom: 16px;
        left: 0;
        right: 0;
        padding: 0px 20px;

        .btn-border {
            background: transparent !important;
            border: 1.5px solid #FF0083;
            border-radius: 5px;
            padding: 12px 25px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color: #ff0083;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0 auto;
            margin-top: 20px;
            height: 46px;

            &:hover {
                background: #FF0083 !important;
                color: #fff;
                // border: 1px solid #FF0083;
            }
        }

        .btn-filled {
            height: 44px;
            padding: 20px 10px;
            border-radius: 12px;
            background: #222D37 !important;
            color: #FFF;
            font-size: 12px;
            font-style: normal;
            border: unset;
            font-weight: 500;
            line-height: 110%;
            /* 13.2px */
            text-transform: capitalize;

            &:hover {
            //    background: #FF0083 !important;
            //     color: #fff;
                border: unset !important;

            }
        }
    }
}

.cardss_parents_new {
    padding: 20px;
}

.abs-text-golden {
    color: #e3cd8b !important;

}

.cardss_parents_new {
    padding: 20px;
    border-radius: 15px;
    background: #D9E7F6;
}

.custom-cards-domain {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    grid-gap: 20px;
}

@media (max-width:1199px) {
    .custom-cards-domain {
        // grid-template-columns: repeat(auto-fill, 28%);
        grid-template-columns: repeat(3, 1fr);
    }

    .card-img-top-div {
        height: 150px;
    }
}


@media (max-width:911px) {
    .custom-cards-domain {
        // grid-template-columns: repeat(auto-fill, 48%);
        grid-template-columns: repeat(2, 1fr);
    }

}

@media (max-width:600px) {
    .custom-cards-domain {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width:390px) {
    // .card-img-top-div {
    //     height: 300px;
    // }
}


@media(min-width:2000px) {
    .custom-cards-domain {
        grid-template-columns: repeat(auto-fill, 18.7%);
    }
}

//   @media(min-width:2300px){
//     .custom-cards-domain{
//       grid-template-columns: repeat(auto-fill , 11.5%);
//     }
//   }