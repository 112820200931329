.befirst {
    padding: 140px 0px;
    position: relative;
    overflow: hidden;

    .nftsdomainimg {
        position: absolute;
        left: 48px;
        top: 47px;

    }

    .right_banner_section {
        padding-top: 122px;
    }

    .elipse {
        position: absolute;
        width: 100%;
        object-fit: cover;
        height: 584px;
        left: 0px;
        z-index: -1;
    }

    .owner {
        display: flex;
        justify-content: space-between;
    }

    .section_one {
        padding: 60px 0px;
        display: flex;
        justify-content: space-between;

        p {
            color: var(--Accent, #FF0083);
            font-size: 16px;
            font-style: normal;
            font-family: 'Pmedium';

            line-height: 130%;
        }

        h1 {
            color: var(--White, #FFF);
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
        }

        h2 {
            color: #0E1120;
            font-size: 53px;
            font-style: normal;
            font-weight: 500;
            line-height: 110%;
            /* 58.3px */
        }

        .whitepara {

            max-width: 490px;
            color: #67717D;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 24px */
            padding-top: 18px;
        }

        .whitetext {
            color: #0E1120;
            font-size: 53px;
            font-style: normal;
            font-weight: 500;
            line-height: 110%;
            /* 58.3px */
        }

        h3 {
            color: var(--White, #FFF);
            width: 490px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            padding-top: 18px;
        }
    }

    .inner-text {
        h6 {
            color: var(--White, #FFF);
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;

            span {
                color: var(--Accent, #FF0083);
                font-size: 60px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
            }
        }

        p {
            font-style: normal;
            font-size: 26px;
            line-height: 140%;
            color: #FFF;
            font-weight: 400;
        }
    }
}

.main_outer {
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-direction: column;

    .right_side {
        width: 759px;

        .flexxdiv {
            display: flex;
            flex-direction: column;
            gap: 78px;
        }

        .innnner_div {
            display: flex;
            justify-content: space-between;

            .cards__sections {
                padding: 15px 20px;
                border-radius: 20px;
                width: 323px;
                background: #E0ECFA;
            }

            p {
                color: var(--Accent, #FF0083);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
            }

            h3 {
                color: #0E1120;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%;
                /* 17.6px */
                padding-bottom: 10px;

            }

            .lowerpara {
                color: #67717D;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                /* 19.2px */
            }
        }
    }

    .innner_two {
        p {
            color: #FF0083;
            font-size: 14px;
            font-style: normal;
            font-family: 'Pmedium';
            line-height: 130%;
            /* 18.2px */
            padding-bottom: 12px;
        }

        h2 {
            color: #0E1120;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
            line-height: 110%;
            /* 44px */
            margin-bottom: 32px;
        }

        span {
            color: var(--Accent, #FF0083);
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
        }
    }
}

.buydomain {
    padding: 60px 0;
    position: relative;
    overflow: hidden;

    .elipse2 {
        position: absolute;
        right: -80px;
        bottom: -116px;
    }

    .inner-text {
        h6 {
            font-style: normal;
            font-size: 40px;
            line-height: 120%;
            color: #FFF;
            margin-bottom: 28px;
            font-weight: 700;
        }

        p {
            font-style: normal;
            font-size: 26px;
            line-height: 140%;
            color: #FFF;
            font-weight: 400;
        }
    }
}

.rewardeveryone {
    position: relative;

    .elipse3 {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        z-index: -1;
        margin-right: auto;
    }
}

.mainsection_three {
    padding: 0px;
    display: flex;
    justify-content: space-between;


    .left_side {
        p {
            color: #67717D;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            /* 22.4px */
            width: 300px;

        }

        h2 {
            color: var(--primary-color, #0E1120);
            font-size: 42px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 50.4px */
            padding-bottom: 12px;
            line-height: 120%;

            span {
                color: var(--primary-color, #0E1120);
                font-size: 42px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                /* 50.4px */
            }
        }
    }

    .input_type {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        border-radius: 15px;
        border: 1px solid var(--stroke, #DCE7F3);
        background: #E7F2FF;
    }

    input {
        border-radius: 15px;
        background: #E0ECFA !important;
        color: #0E1120;
        padding: 22px 15px;

        font-size: 14px;
        border: unset;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 14px */
        width: 390px;

    }

    ::placeholder {
        color: #6E8491;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 14px */
    }

    .contactusstext {
        color: #FF0083 !important;
        font-size: 14px;
        font-style: normal;
        font-family: 'Pmedium';        line-height: 130%;
        /* 18.2px */
    }

    button {
        color: #E8F2FF;
        text-align: center;
        width: 100%;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 18px */
        padding: 20px 10px;
        border-radius: 15px;
        background: #222D37;
        border: unset;
        &:hover{
            background: #FF0083;
        }
    }

    .typpppe {
        position: relative;
    }

    .poxxx {
        position: absolute;
        left: 20px;
        top: 9px;
    }


}

.rewardeveryone {
    padding: 80px 0;
    background: #E0ECFA;

    .inner-text {
        h6 {
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 120%;
            color: #FFF;
            margin-bottom: 28px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 26px;
            line-height: 140%;
            color: #FFF;
        }
    }
}

.mainlast {
    .innnnnner {
        background-image: url(../../../Assests/bgimg.png) !important;
        width: 100%;
        height: 100%;
        /* or specify a fixed height if needed */
        background-size: cover;
        /* Ensures the image covers the full container */
        background-repeat: no-repeat !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        padding: 60px 91px;
        border-radius: 25px;
        background-color: #E0ECFA;
    }
}

.refferprogram {
    object-fit: cover;
    width: 100%;
    overflow: hidden;
    padding-top: 91px;
    position: relative;
    padding-bottom: 91px;

    .myyyelipse {
        position: absolute;
        left: -494px;
        top: -363px;
    }

    .myyyelipse2 {
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        z-index: -1;
        height: 572px;
        object-fit: cover;


    }

    h2 {
        color: var(--systemGrey-900, #212121);
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
        width: 842px;


        span {
            color: var(--systemGrey-900, #212121);
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.2px;
        }
    }

    p {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.2px;
        width: 490px;
    }
}

.myyyelipsenew {
    position: absolute;
    width: 100%;
    top: 0px;
    right: 0px;
    z-index: -1;
}

@media (max-width:600px) {
    .xyxssxssyfyf {
        display: none;
    }

    .befirst .inner-text h6 {
        font-size: 28px;
    }

    .befirst .inner-text p {
        font-size: 16px;
    }

    .rewardeveryone .inner-text h6 {
        font-size: 28px;
    }

    .rewardeveryone .inner-text p {
        font-size: 16px;
    }

    .buydomain .inner-text h6 {
        font-size: 28px;
    }

    .buydomain .inner-text p {
        font-size: 16px;
    }

    .refferprogram .inner-text h6 {
        font-size: 28px;
    }

    .refferprogram .inner-text p {
        font-size: 16px;
    }

    .befirst .inner-text {
        padding-top: 50px;
        max-width: 390px;
        width: 100%;
    }

    .rewardeveryone .inner-text {
        padding-top: 50px;
        max-width: 390px;
        width: 100%;
    }

    .buydomain .inner-text {
        padding-top: 50px;
        max-width: 390px;
        width: 100%;
    }

    .refferprogram .inner-text {
        padding-top: 50px;
        max-width: 390px;
        width: 100%;
    }

    .befirst .section_one h2 {
        font-size: 40px;

        span {
            font-size: 40px;
        }
    }

    .befirst .section_one .whitetext {
        font-size: 40px;
    }

    .refferprogram h2 {
        font-size: 20px;
        padding-top: 116px;
        width: 100%;

        span {
            font-size: 20px;
        }
    }
}

@media (max-width:1200px) {
    .mainsection_three .left_side p {
        text-align: center;
        width: 100%;
    }

    .befirst .section_one {
        flex-direction: column-reverse;
        justify-content: center;
        gap: 50px;
        text-align: center;
        align-items: center;
        padding-bottom: 259px;
    }

    .befirst .section_one h3 {
        width: 100%;
    }

    .mainsection_three {
        flex-direction: column;
        padding: 48px 10px;
        gap: 60px;
        margin-top: 0px;
    }


    .left_side {
        text-align: center !important;
    }

    .mainsection_three input {
        width: 100%;
    }

    .refferprogram {
        margin-top: 0px;
    }

    .refferprogram h6 {
        width: 100%;
        font-size: 30px !important;

        span {
            font-size: 30px !important;
        }
    }

    .refferprogram p {
        width: 100%;
        font-size: 16px !important;
        padding-bottom: 110px !important;
    }

    .refferprogram {
        object-fit: cover;
        width: 100%;
        overflow: hidden;
        padding-top: 45px;
        position: relative;
        padding-bottom: 52px;
    }
}

@media (max-width:992px) {
    .main_outer {
        flex-direction: column;
    }

    .main_outer .innner_two h2 {
        font-size: 40px;
    }

    .main_outer .innner_two h2 {
        font-size: 40px;
    }

    .main_outer .right_side .innnner_div {
        flex-direction: column;
        gap: 50px;
    }

    .main_outer .right_side .flexxdiv {
        gap: 50px;
    }

    .main_outer .right_side {
        width: 100%;
        padding-bottom: 306px;
    }

    .mainlast .innnnnner {

        background-image: url(../../../Assests/lasssst.png) !important;
    }

    .buydomain {
        overflow: hidden;
    }

    .buydomain .elipse2 {
        width: 100%;
    }

    .main_outer .right_side .innnner_div .lowerpara {
        width: 100%;
    }

    .main_outer .right_side .flexxdiv {
        margin-top: 50px;
    }
}