.mycommission-section{
    padding-top: 50px;
    padding-bottom: 100px;
    .back-arrow{
        color: var(--primary-color, #0E1120);
        font-size: 12px;
        border: unset;
        font-style: normal;
        width: 124px;
        font-weight: 500;
        line-height: 110%; /* 13.2px */
        text-transform: capitalize;
        padding: 20px 10px;
        border-radius: 12px;
        background: #E8F2FF;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        margin-bottom: 45px;
 
    }
    .main-heading{
        margin-bottom: 50px;
        h6{
            color: var(--primary-color, #0E1120);
            font-size: 38px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 38px */
        }
    }
    .twice-div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 59px;
        .inner-item{
            padding: 30px;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 0 0 49%;
            h6{
                color: #67717D;

                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%; /* 16.8px */
                padding-top: 25px;
                padding-bottom: 5px;
            }
            h5{
                color: #0E1120;
                font-size: 30px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%; /* 36px */
            }
        }
    }
    .bottom-part{
        .upper-area{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 25px;
            h6{
                color: var(--primary-color, #0E1120);
                font-size: 38px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 38px */
            }
            .btn-claim{
                color: var(--primary-color, #0E1120);
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%; /* 13.2px */
                text-transform: capitalize;

padding: 20px 10px;
border-radius: 12px;
background: var(--secondary-button-color, #D0DEEF) !important;
            }
        }
    }
}

// data table scss here.........................

.datatable{
    .table-responsivecolorbg{
        // border-radius: 10.511px;
        // border: 1.051px solid #282828;
        // background: #1F1F1F;
        // box-shadow: 0px 4.204px 42.044px 0px rgba(0, 0, 0, 0.05);
        padding: 30px 20px;

        

    }
    .upper-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        .left{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span{
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                color: #FFF;
            }
            .dropdown-toggle{
                border-radius: 8.409px;
                background: #272727;
                border-radius: 8px;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                color: #FFF !important;
                border: none !important;
                margin: 0 15px;
                outline: none !important;
                &:focus{
                    box-shadow: none !important;
                }
            }
            .dropdown{
                .dropdown-menu{
                    width: 100%;
                    background: #272727;
                    a{
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 120%;
                        color: #FFF;
                        &:hover{
                            background-color: #FF0083 !important;
                            color: #fff !important;
                        }
                    }
                }
            }
        }
        .right{
            .option-field{
                display: flex;
                align-items: center;
                justify-content: center;
                h6{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 120%;
                    color: #151515;
                    margin-right: 15px;
                }
                input{
                    background: #FFFFFF;
                    border: 1px solid #DFE6E9;
                    border-radius: 7px;
                    max-width: 201px;
                    width: 100%;
                    height: 48px;
                }
            }
        }
    }
    th{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #FFF;
        vertical-align: middle !important;
        border: none;
        padding: 15px 0;
        position: relative;
        white-space: nowrap;
        border: none !important;
    }
    td{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: #FFF;
        vertical-align: middle !important;
        border-top: 1px solid #282828;
        padding: 0;
        padding: 30px 0;
        white-space: nowrap;
    }
    .footer-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-f{
            h6{
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #767676;
            }
        }
        .right-f{
            .page-link{
                border: none;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #BFC3C7;
                &.active{
                    background: #FF0083;
                    border-radius: 5px;
                    color: #fff;
                }
            }
            .page-item:first-child .page-link{
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #BFC3C7;
            }
        }
    }
    .btn-claim{
        background: #FF0083;
        border-radius: 35px;
        border: none;
        padding: 13px 25px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #FFFFFF;
    }
}
.filter-btn{
    display: flex;
    flex-direction: column;
    width: 11px;
    margin-left: 10px;
}
.parent-tag{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
@media (max-width:600px){
    .datatable {
        td{
            text-align: center;
        }
        th{
            text-align: center;
        }
    }
    .datatable .upper-content{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 15px;
    }
    .datatable .footer-content{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }
}


.success-modal{
    background: rgba(0, 0, 0, 0.6);
backdrop-filter: blur(2.5px);
    .modal-content{
        background: #111111;
        border-radius: 10px;
        border: none;
    }
    .modal-dialog{
        max-width: 385px;
    }
    .modal-header{
        background: #111111;
        padding: 20px;
        justify-content: center;
        border: none;
        .modal-title{
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 100%;
            text-align: center;
            text-transform: uppercase;
            color: #FFF;
        }
        .btn-close{
            background: url("../../Assests/close-circle.svg") !important;
            background-size: contain !important;
            background-position: center;
            background-repeat: no-repeat;
            border: none;
            opacity: 1;
            padding: 15px;
            position: absolute;
            top: 16px;
            right: 17px;
        }
    }
    .modal-body{
       padding: 40px;
       h6{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 130%;
        text-align: center;
        text-transform: uppercase;
        color: #FFF;
        margin-top: 21px;
        margin-bottom: 53px;
       }
       .btn-claim{
        background: #FF0083;
        border-radius: 5px;
        border: none;
        padding: 13px 25px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #FFFFFF;
        width: 100%;
    }
    }
}

@media (max-width:600px){
    .mycommission-section .twice-div{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }
    .mycommission-section .bottom-part .upper-area{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 15px;
    }
    .mycommission-section .main-heading h6{
        font-size: 24px;
    }
    .mycommission-section .twice-div .inner-item h6{
        font-size: 16px;
    }
    .mycommission-section .twice-div .inner-item h5{
        font-size: 22px;
    }
    .mycommission-section .twice-div .inner-item{
        width: 100%;
    }
    .mycommission-section .bottom-part .upper-area h6{
        font-size: 18px;
    }
}

.btnDis{
    background-color: #282828!important;
    cursor: not-allowed;
}