.refferal-section {
    position: relative;

    padding-top: 100px;
    padding-bottom: 150px;

    .banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 783px;
        object-fit: cover;
    }

    .main-head {
        margin-bottom: 16px;

        h6 {
            color: var(--primary-color, #0E1120);
            font-size: 38px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 38px */
        }
    }

    .bg-refferal {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        z-index: -1;
        height: 512px;
    }

    .main-heading {
        padding-top: 100px;

        h2 {
            color: #0E1120;
            font-size: 56px;
            text-align: center;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            /* 61.6px */
            margin-bottom: 16px;
        }

        p {
            color: #67717D;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 24px */
        }
    }

    .inner-box {
        border-radius: 20px;
border: 1px solid #DCE7F3;
background: #E7F2FF;
position: relative;
padding: 84px 30px;
        margin-top: 53px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 9999;

        .left {
            .inner-heading {
                margin-bottom: 31px;

                h6 {
                    color: var(--primary-color, #0E1120);
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 110%; /* 28.6px */
                    margin-bottom: 12px;
                }

                p {
                    color: var(--secondary-color, #67717D);
                    text-align: center;                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%; /* 28px */
                }
            }

            .bottom-box {
               


                h6 {
                    color: var(--primary-color, #0E1120);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%; /* 14px */
                    text-transform: capitalize;
                    margin-bottom: 14px;
                }

                .address-div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    border-radius: 15px !important;
                    background: #D8E3F6 !important;
                    padding: 20px !important;


                    p {
                        color: var(--primary-color, #0E1120);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%; /* 16.8px */
                    }
                }
            }
        }
    }

    .inner-box1 {
        border-radius: 20px;
        border: 1px solid #DCE7F3;
        background: #E7F2FF;
        padding: 30px;

        margin-top: 53px;

        .left {
            .bottom-box {
                position: relative;

                h6 {
                    color: #0E1120;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    /* 28.8px */
                    margin-bottom: 20px;
                }

                .imgpen {
                    position: absolute;
                    top: 68px;
                    right: 211px;
                }

                input {
                    border-radius: 15px;
                    background: #DFEAFC;
                    padding: 20px 8px 20px 15px;

                    color: var(--primary-color, #0E1120);
                    font-size: 14px;
                    font-style: normal;
                    border: 1px solid transparent;
                    font-weight: 400;
                    line-height: 130%;
                    
                    /* 18.2px */
                    width: 100%;

                    &::placeholder {
                        color: #6E8491;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%; /* 14px */
                    }
                }
                input:focus {
                    border: 1px solid var(--red, #CF1A12); /* Customize the border color */
                    outline: none; /* Removes the default outline */
                }

                .btn-save {
                    color: #E8F2FF;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                
                    font-weight: 400;
                    line-height: 100%; /* 14px */
                    padding: 20px 38px;
                    border-radius: 12px;
                    background: #222D37;
                    border: unset;          
                white-space: nowrap;     
                &:hover{
                    background: #FF0083 !important;
                }
    
            }
            }
        }
    }

    .refer-tabs {
        margin-top: 35px;
        margin-bottom: 26px;
        border: unset !important;
gap: 12px;
        .nav-link {
            color: #222D37;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 21px */
            text-transform: capitalize;
            border-radius: 15px;
            background: #DFEAFC;
            padding: 13px 40px;
            &:hover{
                background: #BDCBDC;
            }
 
        }

        .nav-item.show .nav-link,
        .nav-link.active {
            color: #FFF;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            border: unset;
            border-color: unset;
            line-height: 150%; /* 21px */
            text-transform: capitalize;
            border-radius: 15px;
background: #222D37;
padding: 13px 40px;

        }
    }

    .table-responsive {
        padding: 25px 0px;

        border: 1px solid #282828;
        overflow-x: unset;
        border-radius: 15px;
        border: 1px solid var(--stroke, #DCE7F3);
        background: #E7F2FF;
    

        th {
            border: none;
            color: var(--secondary-color, #67717D);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 25.226px; /* 210.219% */
            letter-spacing: 0.24px;
            padding: 40px 15px;
            vertical-align: middle;
            border-bottom: 1.051px rgba(255, 255, 255, 0.09);
            white-space: nowrap;

            p {
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }

        td {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #FFF;
            border: none;
            border-top: 1px solid #282828;
            padding: 40px 15px;
            vertical-align: middle;
            white-space: nowrap;
        }
    }

    .footer-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 0px 0px 4px 4px;
        background: #E7F2FF;
        border: 1px solid var(--stroke, #DCE7F3);
        border-radius: 15px;

        padding: 14px 20px;
        margin-top: 10px;

        .left-f {
            h6 {
                color: #77868B;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 110%; /* 15.4px */
                letter-spacing: 0.1px;
            }
        }

        .right-f {
            .page-link:focus {
                box-shadow: none;
                background-color: unset;
            }

            .page-link:hover {
                box-shadow: none;
                background-color: unset;
            }

            .page-link {
                border: none;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                background: transparent;
                color: #A0A1A1;

                &.active {
                    background: #FF0083;
                    border-radius: 5px;
                    color: #FFFFFF;
                }
            }

            .page-item:first-child .page-link {
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #A0A1A1;
            }
        }
    }
}
.button__input_main{
    display: flex;
    align-items: center;
    gap: 25px   ;
    width: 100%;
}

.setting_option{
    width: 100% !important;
}
.banner-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 783px;
    object-fit: cover;
}

.errorTxt {
    color: #FF0083;

}

.cash-back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08), 0px 10px 50px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 24px 20px;
    margin-bottom: 26px;

    p {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        text-transform: capitalize;
        color: #404040;
    }

    h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 27px;
        line-height: 120%;
        color: #FF0083;
    }
}

.total-compensation {
    background: #1F1F1F;
    border-radius: 10px;
    padding: 48px 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
    border-radius: 20px;
    border: 1px solid #DCE7F3;
    background: #E7F2FF;


    .left {
        .text-content {
            h6 {
                color: var(--secondary-color, #67717D);
           
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 28px */
                margin-bottom: 15px;
            }

            h5 {
                color: var(--primary-color, #0E1120);
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 110%; /* 28.6px */

                span {
                    margin-left: 15px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 26px;
                    letter-spacing: 0.1px;
                    color: #FED7FC;
                }
            }
        }
    }

    .right {
        max-width: 233px;
        width: 100%;

        .btn-claimall {
            color: #E8F2FF;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
            padding: 20px 38px;
            border-radius: 12px;
            background: #222D37;
            width: 100%;
            border: none;
            &:hover{
                background: #FF0083 !important;
            }

        }
        .btn-claimall:hover{
            background-color: #FF0083 !important;
        }
    }

}

@media (max-width:991px) {
    .total-compensation .left .text-content h6 {
        font-size: 18px;
    }

    .total-compensation .left .text-content h5 {
        font-size: 28px;
    }
}

@media (max-width:600px) {
    .refferal-section .main-heading p {
        font-size: 16px;
    }

    .refferal-section .inner-box {
        padding: 15px;
        flex-direction: column;
        gap: 25px;
    }

    .refferal-section .inner-box .left .bottom-box {
        padding: 15px;
    }

    .refferal-section .refer-tabs {
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .refferal-section .refer-tabs .nav-link {
        white-space: nowrap;
    }

    .refferal-section .footer-content {
        flex-direction: column;
        justify-content: center;
        gap: 15px;
    }

    .refferal-section .inner-box .left .inner-heading p {
        font-size: 16px;
    }

    .refferal-section .inner-box1 {
        padding: 15px;
    }

    .setting-secti .bg-refferal {
        height: 415px !important;
    }

    .refferal-section .inner-box1 .left .bottom-box .btn-save {
        max-width: 100%;
    }

    .cash-back h6 {
        font-size: 18px;
    }

    .cash-back p {
        font-size: 16px;
    }

    .total-compensation {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 20px;
        gap: 20px;
        margin-bottom: 30px;
    }

    .total-compensation .right {
        max-width: 100%;
    }

    .refferal-section .main-head h6 {
        font-size: 20px;
    }

}

.text-common {
    color: var(--button-color, #222D37) !important;
    line-height: 18px !important;
}

.bbbtn-claim {
    background: #FF0083;
    border-radius: 5px;
    border: none;
    padding: 13px 25px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #FFFFFF;
}

.custom-checkbox {

    .form-group {
        margin-bottom: 0;
    }

    .form-group input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }

    .form-group label {
        position: relative;
        cursor: pointer;
        margin-bottom: 0;
    }

    .form-group label:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 2px solid #ff0083;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 10px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
    }

    .form-group input:checked+label:after {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 9px;
        width: 6px;
        height: 14px;
        border: solid #ff0083;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
}