.domainprofile {
  padding: 80px 0;
  overflow: hidden;

  .domaincards {
    width: 68px;
    height: 68px;
  }

  .dropdown-menu.show {
    padding: unset;
    border-radius: 10px;
    border: 1px solid #FF0083;
    background: #201B1E;
    box-shadow: 0px 10.511px 52.555px 0px rgba(0, 0, 0, 0.05);
    width: 100%;
    overflow: hidden;
  }

  .dropdown-item:hover {
    background: rgba(255, 0, 131, 0.10);
  }

  .dropdown-item {
    padding: 12px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    background: unset;
    color: #FFF;
    font-size: 18.92px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 22.704px */
  }

  .dropdown {
    max-width: 292.204px;
    width: 100%;
  }

  .dropdown .backdrop {
    box-shadow: unset !important;
    outline: unset !important;
    padding: 14.715px 23.124px;
    display: flex;
    align-items: center !important;
    display: flex !important;
    margin-bottom: 16px;
    justify-content: space-between;
    width: 100%;
    border-radius: 155px;
    border: 1px solid rgba(255, 255, 255, 0.03);
    background: rgba(255, 255, 255, 0.03);
    box-shadow: 0px 10.511px 52.555px 0px rgba(0, 0, 0, 0.05);
  }

  .dropdown-toggle::after {
    display: none;
  }

  .show>.btn-success.dropdown-toggle {
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid #FF0083;
    box-shadow: unset;
    outline: unset;


  }

  .btn-success:not(:disabled):not(.disabled):active {
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid #FF0083;
    box-shadow: unset;
    outline: unset;
  }

  .back-btn {
    color: var(--primary-color, #0E1120) !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 13.2px */
    text-transform: capitalize;
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    gap: 12px;
    align-items: center;
    width: 124px !important;
    padding: 20px 10px;
    border-radius: 12px;
    background: #E8F2FF;
    margin-bottom: 20px;
  }

  .top-heading {
    display: flex;
    align-items: center;
    margin-bottom: 50px;

    // padding: 30px 25px;
    .left {
      margin-right: 20px;
    }

    .break-word-sm {
      color: var(--primary-color, #0E1120);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;

      /* 18px */
      span {
        color: var(--primary-color, #0E1120);
        font-family: "KH Teka";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 18px */
      }
    }

    .copiedparatextimg {
      padding: 0px;
      width: unset;
      height: unset;
    }

    .right {
      h4 {
        color: var(--primary-color, #0E1120) !important;
        font-size: 38px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 38px */
        margin-bottom: 12px;
        // padding: 30px 25px;
      }

      span {
        color: var(--primary-color, #0E1120);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 18px */
        display: flex;
        align-items: center;
      }
    }
  }

  .parentts_div {
    padding: 58px 60px;
    border-radius: 15px;
    border: 1px solid var(--stroke, #DCE7F3);
    background: var(--frame-color, #E7F2FF);
    backdrop-filter: blur(35.099998474121094px);
  }

  .sidebar {
    padding: 15px;
    border-radius: 15px;
    border: 1px solid var(--stroke, #DCE7F3);



    // padding: 30px 25px;
    .heading {
      color: var(--primary-color, #0E1120);

      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      /* 24px */
      padding-bottom: 20px;
    }

    .change-my-color {
      margin-right: 20px;
    }

    .nav-link {
      color: #222D37;
      font-size: 14px;
      font-style: normal;
      margin-bottom: 8px;
      font-weight: 400;
      line-height: 100%;
      /* 14px */
      padding: 20px;
      padding: 16px !important;
      display: flex;
      align-items: center;
      border-radius: 15px;
      background: #D0DEEF;
      &:hover{
        background: #BDCBDC;
      }

    }

    .nav-link.active,
    .show>.nav-link {
      border-radius: 15px !important;
      background: #222D37;
      color: var(--button-text-color, #E8F2FF);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 14px */

      .change-my-color path {
        fill: transparent;
        stroke: #E8F2FF !important;
      }
    }
  }

  .content-side {




    .first {
      .cover {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        // background-image: url('https://i.guim.co.uk/img/media/ef8492feb3715ed4de705727d9f513c168a8b196/37_0_1125_675/master/1125.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=d456a2af571d980d8b2985472c262b31');
        position: relative;
        background-color: #620032;
        border-radius: 5px 5px 0px 0px;
        width: 100%;
        height: 164px;
        z-index: 0;

        .viewprofile-btn {
          padding: 12.5px 17.5px;
          background: #620032;
          border: 1px solid #ffffff;
          border-radius: 5px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          color: #ffffff;
          position: absolute;
          right: 20px;
          bottom: 20px;
        }

        .add-cover {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          color: #ffffff;
          position: absolute;
          bottom: 20px;
          left: 200px;
        }

        .add-avatar {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          color: #ff0083;
          position: absolute;
          bottom: -40px;
          left: 200px;
        }
      }

      .profile {
        position: relative;
        background: #ffb3da;
        border: 6px solid #ffffff;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 170px;
        width: 128px;
        height: 128px;
        overflow: hidden;
        margin-top: -60px;
        margin-left: 20px;

        img {
          width: 128px;
          height: 128px;
          object-fit: cover;
          border-radius: 170px;
        }
      }

      .bottom-content {
        padding: 20px;

        .user-form {
          padding-bottom: 35px;
          border-bottom: 1px solid #e3e3e3;
        }

        .socials {
          padding-top: 35px;
          padding-bottom: 35px;
          border-bottom: 1px solid #e3e3e3;

          .socialheading {
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 44px;
            text-transform: capitalize;
            color: #404040;
            margin-bottom: 20px;
          }
        }

        .badges {
          padding-top: 35px;
          padding-bottom: 35px;
          border-bottom: 1px solid #e3e3e3;

          .badgeheading {
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 44px;
            text-transform: capitalize;
            color: #404040;
            margin-bottom: 20px;
          }

          .nft-text {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #404040;
            margin-bottom: 15px;
          }

          .btns-two {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .edit-btn {
              background: #ffffff;
              border: 1px solid #ff0083;
              border-radius: 5px;
              padding: 15px;
              width: 88%;
              display: inline-block;
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 26px;
              color: #ff0083;
              text-align: center;
            }

            .refresh-btn {
              background: #ffffff;
              border: 1px solid #ff0083;
              border-radius: 5px;
              padding: 15px;
              display: inline-block;
              width: 10%;
              text-align: center;
            }
          }

          .inner-span {
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            color: #c2c2c2;
          }
        }

        .metadata {
          padding-top: 35px;
          padding-bottom: 35px;
          border-bottom: 1px solid #e3e3e3;

          .metaheading {
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 100%;
            text-transform: capitalize;
            color: #404040;
            margin-bottom: 20px;
          }

          p {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 100%;
            color: #c2c2c2;
            margin-bottom: 25px;

            a {
              color: #ff0083 !important;
            }
          }

          .humanity {
            h6 {
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 44px;
              text-transform: capitalize;
              color: #404040;
              display: flex;
              align-items: center;

              img {
                margin-right: 10px;
              }
            }

            a {
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 26px;
              color: #ff0083;
              padding: 15px;
              width: 100%;
              background: #ffffff;
              border: 1px solid #ff0083;
              border-radius: 5px;
              display: block;
              text-align: center;
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    .second {
      padding: 20px;

      .sec-heading {
        padding-bottom: 35px;

        h6 {
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 44px;
          text-transform: capitalize;
          color: #404040;
          margin-bottom: 10px;
        }

        p {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 100%;
          color: #aeaeae;

          a {
            color: #ff0083 !important;
          }
        }
      }

      .option-field {
        .tile {
          background: unset;
          border: 1px solid #efefef;
          box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
          padding: 9px 12px;
          display: flex;
          align-items: center;
          margin-bottom: 5px;

          a {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #3c3c3c;
            padding: 14px 30px;
            text-align: center;
            border: 1px solid #efefef;
            border-radius: 5px;
            margin-right: 10px;
          }

          input {
            background: transparent;
            border: none;
          }
        }
      }

      .addcategory-btn {
        background: #ffffff;
        border: 1px solid #ff0083;
        border-radius: 5px;
        padding: 15px;
        width: 100%;
        display: block;
        text-align: center;
        margin-top: 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 110%;
        color: #ff0083;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 35px;
      }
    }

    .third {
      padding: 20px;

      .thirdheading {
        h6 {
          color: var(--primary-color, #0E1120) !important;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 24px */
          text-transform: capitalize;
          margin-bottom: 10px;
        }

        p {
          color: var(--secondary-color, #67717D);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          /* 19.2px */
          padding-bottom: 25px;

          a {
            color: var(--secondary-color, #67717D);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
          }
        }
      }

      .option-field {
        padding-bottom: 35px;
        border-bottom: 1px solid #DCE7F3;
      }

      .inner-checks {
        padding-top: 16px;



        .custom-control {
          margin-bottom: 20px;
        }

        label {
          color: var(--primary-color, #0E1120);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          /* 16px */
        }

        .custom-control-label::before {
          background-color: transparent;
          border: 1.5px solid #000;
          
          border-radius: 20px;

          &:focus {
            box-shadow: none;
            outline: none;
          }
        }

        .custom-control-input:checked~.custom-control-label::before {
          background-color: #ff0083;
          border: 1px solid transparent;
          border-radius: 20px;
        }

        .custom-control-input:focus~.custom-control-label::before {
          box-shadow: none;
        }
      }

      .transfer-btn {
        color: var(--button-text-color, #E8F2FF);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        /* 24px */
        text-transform: capitalize;
        padding: 20px 16px;
        border-radius: 15px;
        background: var(--button-color, #222D37) !important;
        border: unset;
        margin-top: 35px;
        margin-bottom: 15px;
         &:hover{
          background: #FF0083 !important;
         }
      }
    }

    .fifth {
      padding: 0px;

      .headingfifth {
        text-align: center;
        margin-bottom: 20px;

        h6 {
          color: var(--primary-color, #0E1120);
          font-size: 23.124px;
          font-style: normal;
          font-weight: 700;
          line-height: 46.248px;
          /* 200% */
          text-transform: capitalize;
          text-align: start;



        }
      }

      .bottom-content {
        border-radius: 5px;
        // background: #1F1F1F;
        padding: 0px;

        .inner-card {
          padding-bottom: 35px;
      
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .left {
            h6 {
              color: var(--primary-color, #0E1120) !important;
              font-size: 23.124px;
              font-style: normal;
              font-weight: 700;
              line-height: 46.248px;
              /* 200% */
              text-transform: capitalize;
            }

            h5 {
              color: var(--secondary-color, #67717D) !important;
              font-size: 18.92px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
              /* 22.704px */
              margin-bottom: 30px;

              span {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 120%;
                background: linear-gradient(240.1deg, #7a1df2 -9.36%, #ff0083 111.36%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
              }
            }

            .list-btn {
              background: #e9e9e9;
              border-radius: 5px;
              padding: 15.5px 38.5px;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 120%;
              color: #747474;
            }

            .opensea {
              padding: 20px 16px;
              border-radius: 15px;
              background: var(--secondary-button-color, #D0DEEF);
              color: #0E1120;
              font-size: 16.818px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
              /* 20.181px */

              &:hover{
                background: #BDCBDC;
              }
            }
          }
        }

        .last-inline-text {
          display: flex;
          align-items: center;
          margin-top: 30px;

          p {
            color: var(--primary-color, #0E1120);
            font-size: 18.92px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%; /* 24.596px */
            width: 90%;
          }
        }
      }
    }

    .six {
      padding: 20px;

      .main-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
          margin-bottom: 25px;
        }

        h6 {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 44px;
          text-transform: capitalize;
          color: #404040;
          margin-bottom: 10px;
        }

        p {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 120%;
          text-align: center;
          color: #b7b7b7;
          margin-bottom: 30px;
        }

        .start-btn {
          background: #ff0083;
          border-radius: 5px;
          padding: 15.5px;
          display: block;
          text-align: center;
          width: 100%;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 120%;
          color: #ffffff;
        }
      }
    }
  }

  .option-field {
    margin-bottom: 15px;

    label {
      color: var(--primary-color, #0E1120);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      /* 18px */
      text-transform: capitalize;
      display: flex;
      align-items: center;
    }

    input {

      border-radius: 15px;
      background: var(--text-feild-color, #DFEAFC);
      padding: 20px;
      border: unset;
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      padding-right: 70px;
      color: #0E1120;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;

      /* 16.8px */
      &::placeholder {
        color: var(--text-placeholder, #6E8491);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 22.4px */
      }
    }

    textarea {
      background: #ffffff;
      border: 1px solid #efefef;
      box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      height: 127px;
      width: 100%;
      padding: 14px 22px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      text-transform: capitalize;
      color: #000;

      &::placeholder {
        color: #b1b1b1;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .bottom-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    button {
      color: var(--button-text-color, #E8F2FF);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      width: 127px;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* 24px */
      text-transform: capitalize;
      padding: 20px 16px;
      border-radius: 15px;
      border: unset;
      background: var(--button-color, #222D37);
      width: 49%;

      &:hover {
        background: #FF0083;
      }

      &:last-child {
        color: var(--primary-color, #0E1120);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        /* 24px */
        text-transform: capitalize;
        padding: 20px 16px;
        border-radius: 15px;
        background: var(--secondary-button-color, #D0DEEF);

        &:hover {
          background: #BDCBDC;
        }


      }
    }
  }

  .cover {
    position: relative;
    background: #620032;
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    height: 164px;
    z-index: 0;

    .viewprofile-btn {
      padding: 12.5px 17.5px;
      background: #620032;
      border: 1px solid #ffffff;
      border-radius: 5px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color: #ffffff;
      position: absolute;
      right: 20px;
      bottom: 20px;
    }

    .share-btn {
      display: flex;
      padding: 12.5px 17.5px;
      background: #620032;
      border-radius: 5px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color: #ffffff;
      position: absolute;
      right: 20px;
      bottom: 20px;
    }

    .add-cover {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color: #ffffff;
      position: absolute;
      bottom: 20px;
      left: 200px;
    }

    .add-avatar {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color: #ff0083;
      position: absolute;
      bottom: -40px;
      left: 200px;
    }
  }

  .profile {
    position: relative;
    background: #ffb3da;
    border: 6px solid #ffffff;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
    border-radius: 170px;
    width: 128px;
    height: 128px;
    overflow: hidden;
    margin-top: -60px;
    margin-left: 20px;

    img {
      width: 128px;
      height: 128px;
      object-fit: cover;
      border-radius: 170px;
    }
  }

  .mainprofile-left {
    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 33px;
      line-height: 100%;
      color: #ff0083;
      display: flex;
      align-items: center;
      margin-top: 29px;
      margin-bottom: 25px;
    }

    .inner-p {
      display: flex;
      align-items: center;

      p {
        background: #f2f2f2;
        border-radius: 5px;
        padding: 10px 18px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        color: #434343;
        display: flex;
        align-items: center;
        margin-right: 10px;
      }
    }
  }

  .mainprofile-right {
    h6 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 110%;
      color: #292929;
      margin-bottom: 15px;

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        color: #434343;
        background: #f2f2f2;
        border-radius: 5px;
        padding: 5px;
      }
    }

    .bottom-card {
      background: #f2f2f2;
      border: 1px solid #efefef;
      box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      padding: 20px;

      .top-parent {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .parent-left {
          display: flex;
          align-items: center;

          .left {
            margin-right: 10px;
          }
        }

        .parent-right {
          a {
            padding: 12.5px 19px;
            background: #f2f2f2;
            border: 1px solid #e1e1e1;
            border-radius: 5px;
          }
        }
      }
    }
  }

  .badgenew {
    margin-top: 30px;

    h6 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 110%;
      color: #292929;
      margin-bottom: 15px;
    }

    .parent {
      display: flex;
      align-items: center;

      .left {
        margin-right: 10px;
      }

      .right {
        h6 {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #404040;
          margin-bottom: 2px;
        }

        p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #7a798a;
        }
      }
    }
  }

  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    height: 55px;
    // color: #495057;
    vertical-align: middle;
    background: #fff url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Arrow-down.svg) no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .ccl {
    color: #b1b1b1 !important;
  }

  .website {
    padding: 0px;


    select {

      border-radius: 15px;
      background: var(--text-feild-color, #DFEAFC);
      border: unset !important;
      box-shadow: unset !important;
      width: 100%;
      color: var(--text-placeholder, #6E8491) !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      /* 22.4px */
    }

    .top {
      h5 {

        margin-bottom: 30px;
        color: var(--primary-color, #0E1120);

        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 24px */
      }
    }

    .myfeild {
      position: relative;
      max-width: 200px;

      select {
        width: 100% !important;
        cursor: pointer;
      }

      .arrowpink {
        position: absolute;
        top: 24px;
        right: 10px;
        pointer-events: none;
      }
    }

    .option-field {
      position: relative;
gap: 30px;
      label {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 44px;
        text-transform: capitalize;
        color: #FFF;
      }

      .copy {
        position: absolute;
        top: 15px;
        right: 20px;
      }
    }
  }

  .spacetop {
    height: 30px;
    width: 100%;
    background: #eeeeee;
    // border: 1px solid #efefef;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
  }

  .webisteUpload {
    background-color: rgb(255, 255, 255);

    .top {
      h5 {
        margin-bottom: 10px;
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        text-transform: capitalize;
        color: #aeaeae;
      }
    }

    .uploadfiles-div {
      margin-top: 30px;
      padding: 25px 0px;
      background: #f7f7f7;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p:first-child {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        color: #b5b5b5;
      }

      p:nth-child(2) {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        /* identical to box height, or 20px */
        margin: 5px 0px 15px 0px;
        color: #b5b5b5;
      }

      button {
        background: #ff0083;
        border-radius: 5px;
        border: none;
        padding: 17px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        text-align: center;
        color: #ffffff;
        width: 100%;
      }
    }
  }

  .email {
    padding: 30px 50px;

    h4 {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 44px;
      text-transform: capitalize;
      color: #404040;
      margin-bottom: 10px;
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      color: #b7b7b7;

      a {
        color: deeppink;
      }

      margin-bottom: 30px;
    }

    .switch-elem {
      span {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: #979797;
      }

      .bold-color {
        font-weight: 700;
        color: #000;
      }
    }

    .toggle {
      cursor: pointer;
      display: inline-block;
    }

    .toggle-switch {
      display: inline-block;
      background: #ccc;
      border-radius: 16px;
      width: 58px;
      height: 32px;
      position: relative;
      vertical-align: middle;
      transition: background 0.25s;
    }

    .toggle-switch:before,
    .toggle-switch:after {
      content: "";
    }

    .toggle-switch:before {
      display: block;
      background: linear-gradient(to bottom, #fff 0%, #eee 100%);
      border-radius: 50%;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
      width: 24px;
      height: 24px;
      position: absolute;
      top: 4px;
      left: 4px;
      transition: left 0.25s;
    }

    .toggle:hover .toggle-switch:before {
      background: linear-gradient(to bottom, #fff 0%, #fff 100%);
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    }

    .toggle-checkbox:checked+.toggle-switch {
      background: #ff0083;
    }

    .toggle-checkbox:checked+.toggle-switch:before {
      left: 30px;
    }

    .toggle-checkbox {
      position: absolute;
      visibility: hidden;
    }

    .toggle-label {
      margin-left: 5px;
      position: relative;
      top: 2px;
    }
  }
}
.last-inline-text{
  display: flex;
  align-items: center;
  gap: 15px;
}
.inputt_topper_div {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.tooop_inpiut {
  width: 100%;
}
.savebutttton{
  padding: 20px 16px;
width: 127px !important;
}

.vcancelbutttton{
  padding: 20px 16px;
  width: 127px !important; 
}
.dropselect {}

.myyyyyydv {
  overflow: hidden;

  .myyyelipse {
    position: absolute;
    left: -494px;

  }

  .myyyelipse2 {
    position: absolute;
    right: 0PX;
    top: -150px;

  }
}

.domainprofile1 {
  padding-top: 0 !important;

  .profile {
    margin-left: 70px;
  }

  .share-btn {
    right: 70px !important;
  }
}

.subDomain {
  .subInner {
    margin-bottom: 20px;
  }

  .domainDelete {
    border-radius: 15px;
    background: #DFEAFC;
    height: 93.431px;
padding: 13px 23px; 
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .imgName {
      h6 {
        margin-left: 15px;
        color: #0E1120;
        font-size: 18.92px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 18.92px */
      }
    }
  }
}

@media (max-width: 1199px) {
  .domainprofile1 {
    .profile {
      margin-left: 20px;
    }

    .share-btn {
      right: 20px !important;
    }
  }

  .domainprofile .content-side .fifth .headingfifth h6::before {
    width: 200px;
    left: -241px;
  }

  .domainprofile .content-side .fifth .headingfifth h6::after {
    width: 200px;
    right: -241px;
  }
}

.sidebar-icon {
  display: none;
}

@media (max-width: 991px) {
  .sidebar-icon {
    display: block !important;
  }

  .btn-success {
    display: block !important;
  }

  .domainprofile .close-btn {
    display: block !important;
    position: absolute;
    right: 25px;
    top: 25px;
  }

  .mobielSidebarHide {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -100%;
    z-index: 99;
    /* width: 100%; */
    animation-name: moveInleft;
    animation-duration: 2s;
  }

  @keyframes moveInleft {
    0% {
      opacity: 1;
      transform: translateX(300px);
    }

    100% {
      opacity: 0;
      transform: translateX(-100px);
    }
  }

  .mobielSidebarShow {
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none !important;
    }

    left: 0%;
    z-index: 99;
    /* width: 100%; */
    animation-name: moveInRight;
    animation-duration: 1s;
    padding: 0;
  }

  @keyframes moveInRight {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }

    100% {
      opacity: 1;
      transform: translate(0);
    }
  }

  .mobile-side-none {
    display: none;
  }

  .padd-md {
    padding: 0;
  }

  .domainprofile .top-heading {
    padding: 0 15px;
  }

  .mainprofile-right {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .sidebar-icon {
    position: absolute;
    top: 0;
    right: 20px;
  }

  .domainprofile .top-heading {
    margin-top: 50px;
  }
}

@media (max-width: 600px) {
  .padd-md {
    padding: 0;
  }

  .domainprofile .content-side .first .cover .add-cover {
    bottom: unset;
    top: 20px;
  }

  .domainprofile .content-side .first .bottom-content .badges .btns-two .edit-btn {
    width: 75%;
  }

  .domainprofile .content-side .first .bottom-content .badges .btns-two .refresh-btn {
    width: 22%;
  }

  // .domainprofile .bottom-btn {
  //   flex-direction: column;
  // }
  .domainprofile .bottom-btn button {
    width: 100%;
  }

  .domainprofile .tile p {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .domainprofile .content-side .fifth .headingfifth h6::before {
    display: none;
  }

  .domainprofile .content-side .fifth .headingfifth h6::after {
    display: none;
  }

  .domainprofile .content-side .fifth .bottom-content .inner-card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
  }

  .domainprofile .content-side .fifth .bottom-content .inner-card .left {
    text-align: center;
    order: 2;
    margin-top: 20px;
  }

  .domainprofile .content-side .fifth .bottom-content .last-inline-text p {
    font-size: 14px;
  }

  .domainprofile .content-side .six .main-content h6 {
    text-align: center;
  }

  .domainprofile .content-side .six .main-content h6 {
    font-size: 20px;
  }

  .domainprofile .content-side .six .main-content p {
    font-size: 16px;
  }

  .domainprofile {
    padding: 0 !important;
  }

  .domainprofile .sidebar {
    height: 100%;
  }

  .domainprofile .mainprofile-right .bottom-card .top-parent {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px 0;
  }

  .subDomain {
    .subInner {
      flex-wrap: wrap;

      .bottom-btn {
        margin-top: 20px !important;
        display: flex !important;
        flex-direction: row;
      }
    }
  }

  .subDomain .domainDelete {
    padding: 10px;
  }

  .subDomain .domainDelete .imgName h6 {
    margin-left: 15px;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFF;
    margin-top: 3px;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .domainprofile .sidebar .nav-link {
    padding: 21px 25px;
  }

  .domainprofile .sidebar .nav-link.active,
  .domainprofile .sidebar .show>.nav-link {
    padding: 21px 25px;
  }
}

.cursorNA {
  cursor: not-allowed !important;
}

.doge {
  width: 28px;
}

.sugg {
  color: var(--primary-color, #0E1120);

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 24px */
}

.suggP {
  color: var(--text-placeholder, #6E8491) !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
  display: flex;
  align-items: center;

}

.domainprofile .top-heading .right h4 {
  color: #ff0083;
}


.domainprofile .content-side .second .sec-heading h6 {
  color: #FF0083;
}

.domainprofile .content-side .third .thirdheading h6 {
  color: #FF0083;
}

.domainprofile .option-field label {
  // color: #ff0083;
}

.domainprofile .content-side .fifth .bottom-content .inner-card .left h6 {
  color: #ff0083;
}

.domainprofile .content-side .first .cover {
  background-color: #ff0083;
}

.addAva {
  position: relative;
  width: 35px
}

.addProfile {
  bottom: 12%;
  position: absolute;
  left: 67px;
  padding: 6px;
  background: #ffffff;
  width: 35px !important;
  height: 34px !important;
  cursor: pointer;
  border-radius: 100px;
  filter: drop-shadow(1px 2px 2px black);
}


@media (max-width:600px) {

  .domainprofile .website select {
    width: 100% !important;
    height: 54px;
  }

  .domainprofile .top-heading .right span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #FFF;
  }

  .domainprofile .website .myfeild .arrowpink {
    top: 17px;
  }

  .domainprofile .content-side .third .thirdheading h6 {
    line-height: 100%;
  }

  .subDomain .domainDelete {
    flex-direction: column;
    gap: 25px;
    align-items: flex-start !important;
    justify-content: flex-start;
  }

  .img-btn-outline {
    width: 100% !important;
    justify-content: center;
  }

  .domainprofile .website {
    padding: 20px !important;
  }

  .subDomain .subInner .bottom-btn {
    width: 100%;
  }

  .suggP {
    flex-direction: column;
  }
}

.forellipsisset {
  width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media(max-width:768px) {
  .forellipsisset {
    width: 257px;
  }
}