.account{
    padding: 80px 0;
    overflow: hidden;
    .myyyelipse{
        position: absolute;
        left: -494px;
        top: -363px;
    }

    .account-heading{
        margin-bottom: 40px;
        h3{
            font-style: normal;
            font-weight: 600;
            font-size: 45px;
            line-height: 100%;
            color: #FF0083;
            margin-bottom: 20px;
        }
        h5{
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 100%;
            color: #fff;
            margin-bottom: 20px;
        }
        h6{
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 100%;
            color: #fff;
        }
    }
    .bottom-heading{
        .top-card{
            background: #FFFFFF;
            border: 1px solid #EFEFEF;
            box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            margin-bottom: 40px;
            position: relative;
            $border: 1.5px;
            color: #FFF;
            background: #2b2326;
            background-clip: padding-box;
            border: solid $border transparent;
            border-radius: 30px;
    
        
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                margin: -$border;
                /* !importanté */
                border-radius: inherit;
                /* !importanté */
                background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
            }



            .heading{
                padding: 20px;
                h4{
                    font-style: normal;
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 110%;
                    color: #fff;
                }
            }
            .inner-text{
               
                padding: 50px;
                h6{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 100%;
                    color: #FF0083;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
            }
        }
        .bottom-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left{
                h6{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 110%;
                    color: #fff;
                    margin-bottom: 15px;
                }
                p{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 100%;
                    color: #FF0083;
                }
            }
            .right{
                a{
                    
                    border: 1px solid #FF0083;
                    border-radius: 5px;
                    padding: 14px 20px; 
                    display: flex;
                    align-items: center;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 110%;
                    color: #FF0083;
                }
            }
        }
        .right{
            .card-right{
                background: #FFFFFF;
                border: 1px solid #EFEFEF;
                box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
                border-radius: 10px;
                position: relative;
                $border: 1.5px;
                color: #FFF;
                background: #2b2326;
                background-clip: padding-box;
                border: solid $border transparent;
                border-radius: 30px;
        
            
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: -1;
                    margin: -$border;
                    /* !importanté */
                    border-radius: inherit;
                    /* !importanté */
                    background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
                }


                .heading{
                    padding: 20px;
                    h4{
                        font-style: normal;
                        font-weight: 700;
                        font-size: 22px;
                        line-height: 110%;
                        color: #fff;
                    }
                }
                .bottom-content1{
                    padding: 20px;
                    .parent{
                        display: flex;
                        align-items: center;
                        margin-bottom: 25px;
                        .right{
                            margin-left: 8px;
                            p{
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 20px;
                                color: #7A798A;
                            }
                            h6{
                                font-style: normal;
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 22px;
                                color: #fff;
                            }
                        }
                    }
                    .reset-btn{
                        display: block;
                        width: 100%;
                        font-style: normal;
                       font-weight: 600;
                        font-size: 18px;
                        line-height: 110%;
                        color: #FF0083;
                        margin-bottom: 15px;
                    }
                    .change-btn{
                        font-style: normal;
                       font-weight: 600;
                        font-size: 18px;
                        line-height: 110%;
                        color: #FF0083;
                    }
                }
            }
        }
    }
}
.twofactor-modal{
    background: rgba(32, 30, 30, 0.65);
    backdrop-filter: blur(2px);
    .btn-close{
        background: transparent;
        background-image: url("../../Assests/close-btn.svg") !important;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 20px;
        position: absolute;
        top: 15px;
        right: 20px;
        border: none;

    }
    .modal-content{
        background: #FFFFFF;
        backdrop-filter: blur(32px);
        border-radius: 15px;
    }
    .modal-body{
        padding: 20px;
    }
    .modal-header{
        justify-content: center;
        border: none;
        padding: 40px 20px;
        .modal-title{
            
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            color: #171515;
            text-align: center;
        }
    }
        .main-content{
            .inner-parent{
                display: flex;
                align-items: flex-start;
                margin-bottom: 35px;
                .left{
                    width: 268px;
                    margin-top: -10px;
                }
                .right{
                    h5{
                        font-style: normal;
                        font-weight: 700;
                        font-size: 22px;
                        line-height: 30px;
                        color: #171515;
                    }
                    p{
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 30px;
                        color: #171515;
                        a{
                            color: #FF0083;
                        }
                    }
                }
            }
            .barscan{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-bottom: 30px;
            }
            .card-down{
                background-color: transparent;
                border: 1px solid #FF0083;
                border-radius: 5px;
                padding: 10px 20px;
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                h6{
                    margin-left: 17px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 130%;
                    color: #FF0083;
                }
            }
        }

}
.twofactor-modal1{
     .main-content .inner-parent .left{
        width: 80px;
        margin-top: -10px;
     }
   .main-content .inner-parent .right h5{
    margin-bottom: 19px;
   }
     .bottom-code{
        margin-bottom: 30px;
        label{
            display: block;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #333333;
            margin-bottom: 10px;
        }
        input{
            background: transparent;
            border: 1px solid #DCDCDC;
            border-radius: 5px;
            padding: 14.5px 18px;
            width: 100%;
        }
     }
}
.confirm-email{
    .upper-content{
        h4{
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 30px;
            color: #171515;
            text-align: center;
            margin-bottom: 30px;
        }
        p{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            text-align: center;
            color: #171515;
            margin-bottom: 50px;
        }
    }
}
.reset-password{
    background: rgba(32, 30, 30, 0.65);
    backdrop-filter: blur(2px);
    .btn-close{
        display: none;
    }
    .modal-header{
        padding: 20px 32px;
        justify-content: center;
        border: none;
    }
    .modal-dialog{
        max-width: 350px;
    }
    .modal-body{
        padding: 20px;
        padding-bottom: 35px;
    }
    .modal-title{
        
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        text-transform: uppercase;
        color: #000000;
    }
    .option-field{
        margin-bottom: 15px;
        label{
            display: block;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #333333;
            margin-bottom: 10px;
        }
        input{
            background: transparent;
            border: 1px solid #DCDCDC;
            border-radius: 5px;
            padding: 14px 18px;
            width: 100%;
            color: #000;
        }
    }
}
.success-modal{
    p{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: #333333;
        a{
            display: block;
            color: #FF0083;
        }
    }
}
.changeemail-modal{
    .verify-text{
        .verify{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            color: #333333;
            margin-bottom: 25px;
        }
        h6{
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 120%;
            color: #333333;
            text-align: center;
            margin-bottom: 10px;
        }
        .send{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            color: #333333;
            margin-bottom: 15px;
        }
        span{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            color: #333333;
            display: block;
            margin-bottom: 25px;
        }
    }
    .bottom-item{
        input{
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #D4D4D4;
            border-radius: 5px;
            padding: 15px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            color: #000;
            &::placeholder{
                color: #747474;
            }
        }
        .confirm-btn{
            background: #E9E9E9;
            border-radius: 5px;
            padding: 15px;
            width: 100%;
            display: block;
            text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #747474;
            margin-top: 10px;
            margin-bottom: 25px;
        }
        .confirm{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            color: #333333;
            margin-bottom: 25px;
        }
        .gethelp{
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-style: normal;
           font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            text-align: center;
            color: #FF0083;

        }
    }
    .para-last{
        p{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #333333;
            a{
                display: block;
                color: #FF0083 !important;
            }
        }
    }
}
.next-btn{
    background: #FF0083;
    border-radius: 5px;
    padding: 17px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #FFFFFF !important;
    width: 100%;
    display: block;
    text-align: center;
    cursor: pointer;
    &:hover{
        color: #fff;
    }
}
.cancel-btn{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #FF0083;
    display: block;
    margin-top: 30px;
    &:hover{
        color: #FF0083;
    }
}

@media (max-width:1199px){
    .padd-lg{
        padding: 0;
    }
    .account .bottom-heading .bottom-content{
        margin-bottom: 40px;
    }
}

@media (max-width:600px){
    .account .account-heading h3{
        font-size: 38px;
    }
    .account .account-heading h5{
        font-size: 22px;
    }
    .account .account-heading h6{
        font-size: 16px;
    }
    .account .bottom-heading .top-card .inner-text{
        padding: 35px;
    }
    .account .bottom-heading .top-card .inner-text h6{
        font-size: 15px;
    }
    .account .bottom-heading .bottom-content{
        flex-direction: column;
        align-items: flex-start;
    }
    .account .bottom-heading .bottom-content .right a{
        margin-top: 20px;
    }
    .twofactor-modal .main-content .inner-parent .left{
        width: 375px;
    }
    .twofactor-modal .btn-close{
        top: 4px;
        right: 6px;
    }
    .twofactor-modal1 .main-content .inner-parent .left{
        width: 80px !important;
    }
}


.accountsetting1{
    padding: 80px 0;
    .top-heading{
        margin-bottom: 50px;
        h1{
            font-style: normal;
            font-weight: 600;
            font-size: 45px;
            line-height: 100%;
            color: #FF0083;
        }
    }
    .bottom-card{
        background: #FFFFFF;
        border: 1px solid #EFEFEF;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 30px;

        position: relative;
        $border: 1.5px;
        color: #FFF;
        background: #2b2326;
        background-clip: padding-box;
        border: solid $border transparent;
        border-radius: 30px;

    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            /* !importanté */
            border-radius: inherit;
            /* !importanté */
            background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
        }
        h4{
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 110%;
            color: #fff;
            padding-bottom: 20px;
            border-bottom: 1px solid #EFEFEF;
        }
        .option-field{
            padding-top: 20px;
            label{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                color: #fff;
                margin-bottom: 12px;
            }
            .text{
                background: unset;
                border: 1px solid #FF0083;
                border-radius: 35px;
                padding: 14px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 500px;
                color: #FFF;
                p{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 140%;
                    color: #fff;
                }
                h6{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 140%;
                    color: #FF0083;
                }
            }
        }
    }
}

@media (max-width:600px){
    .accountsetting1 .bottom-card .option-field .text{
        width: 100%;
    }
    .accountsetting1 .top-heading h1{
        font-size: 28px !important;
    }
    .accountsetting1 .bottom-card{
        padding: 15px;
    }
}