.domain-search {
  min-height: 40vh;
  padding-bottom: 79px;

  .availableDomain {
    position: relative;
    /* Other styling for your domain container */
  }

  .domainRightHeartDiv {
    opacity: 0;
    /* Initially hidden */
    transition: opacity 0.3s ease;
    /* Smooth transition effect */
  }

  .availableDomain:hover .domainRightHeartDiv {
    opacity: 1;
    /* Make visible on hover */
  }

  .active1 {
    border-radius: 50px;
    background: #ff0083;
  }

  .active2 {
    border-radius: 50px;
    background: #ff0083;
  }

  .active3 {
    border-radius: 50px;
    background: #ff0083;
  }

  .progress_mains {
    max-width: 588px;
    width: 100%;
    margin: 0 auto;

    button {
      border-radius: 12px;
      background: #E8F2FF;
      color: var(--primary-color, #0E1120);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      /* 13.2px */
      text-transform: capitalize;
      display: flex;
      padding: 12.613px 16.818px;
      justify-content: center;
      align-items: center;
      gap: 12.613px;

      border: unset;
      margin-bottom: 50px;
    }

    .top_tab_data {
      display: flex;
      align-items: center;
      gap: 13px;
      width: 100%;
      margin-bottom: 20px;

      .availbilty {
        width: 100%;
      }

      h5 {
        height: 6px;
        align-self: stretch;

        width: 100%;
        border-radius: 10px;
        background: #E8F2FF;
      }

      p {
 
        padding-top: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        width: 100%;
        color: #67717D;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 110%; /* 15.4px */
white-space: nowrap;
      }
    }
  }

  .progresss {
    width: 500px;
    margin: 0 auto;
  }

  .domaincardlarge {

    padding: 50px 34px !important;


    p {
      position: absolute;
      // top: 12% !important;
      // left: 22% !important;
    }
  }

  .botttoms_cards {
    display: flex;
    padding: 10px 15px;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.05);

    p {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 15.385px;
      /* 128.21% */
    }

    h1 {

      text-align: right !important;

      color: #000;
      text-align: right;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 15.385px;
      /* 109.894% */
      padding-top: 10px;
    }
  }

  .top_domainss {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
  }

  h1 {
    color: #0E1120;
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 61.6px */
    padding-top: 40px;

    /* 47.299px */
  }

  .domainss {
    color: #0E1120;
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 61.6px */
  }

  .input-field {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .input-field {
    position: relative;
    width: 100%;
    margin: 0 auto;
    margin: 50px 0px;

    .mysearchinput {
      // max-width: 900px;
      // width: 100%;
      position: relative;

      .crosImg {
        position: absolute;
        right: 20px;
        top: 30px;
      }

      .crosImg2 {
        position: absolute;
        left: 507px;
        top: 17px;
        padding: 0px;

      }

      input {
        width: 547px;
        height: 60px;
        padding: 14px 8px 14px 15px;
        border-radius: 15px;
        background: #DFEAFC;
        border: unset;
        padding-left: 59px;
        outline: none;
        font-weight: 500;
        font-size: 16px !important;
        color: #222D37;
        padding-right: 50px;

        &::placeholder {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 100%;
          color: #ababab;
        }
      }

      .input-icon {
        position: absolute;
        top: 21px;
        left: 20px;
      }

      .inpppput {
        border-radius: 39px;
        border: 1px solid var(--Accent, #ff0083);
        padding: 12px 12px 12px 24px;
        background-color: unset;
      }
    }

    .btn-searchbar {
      // position: absolute !important;
      top: 11px;
      right: 14px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      padding: 16px 38px;

      border-radius: 12px;
      background: #222D37;
      color: #E8F2FF;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      height: 60px;
      font-weight: 400;
      line-height: 100%;
      /* 12px */
      border: transparent;
      color: #ffffff;
      &:hover{
        background: #FF0083;
      }
    }
  }

  .searchIcondiv {
    padding: 55px 0px;
    text-align: center;

    p {
      font-weight: 400;
      font-style: normal;
      font-size: 26px;
      text-align: center;
      color: #000000;
    }

    img {
      margin-bottom: 20px;
      width: 60px;
    }
  }

  .actualResultDiv {
    p {
      font-weight: 600;
      font-size: 18px;
      color: #000000;
    }

    .btn-outline-pink {
      background: #fff7fb;
      border: 1px solid #ff0083;
      border-radius: 6px;
      padding: 10px 18px;
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      color: #ff0083;
    }

    .btn-outline-grey {
      margin-left: 8px;
      background: #ffffff;
      border: 1px solid #ffd9ed;
      border-radius: 6px;
      padding: 10px 18px;
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      color: #868686;
    }

    .artabs-navs {
      margin-top: 20px;
      width: 100%;
      border-width: 1px 1px 1px 1px;
      border-radius: 10px;
      border-style: solid;
      border-color: #efefef;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .tabb2 {
        width: 20%;
        padding: 20px 0px;
        text-align: center;
        border-left: 1px solid #efefef;
        background: #ffe8f4;
        border-bottom: 3px solid #ff0083;

        p {
          font-weight: 600;
          font-style: normal;
          font-size: 18px;
          color: #292929;
        }

        .img-p {
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            margin-left: 6.5px;
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            color: #5a5a5a;
          }
        }
      }

      .tabb {
        width: 20%;
        padding: 20px 0px;
        text-align: center;
        border-left: 1px solid #efefef;

        p {
          font-weight: 600;
          font-style: normal;
          font-size: 18px;
          color: #292929;
        }

        .img-p {
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            margin-left: 6.5px;
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            color: #5a5a5a;
          }
        }
      }
    }
  }

  .availableDomain {
    // background-image: url(../../Assests/domaincard.svg) !important;
    // background-repeat: no-repeat !important;
    // background-size: cover !important;
    /* Ensures the image covers the entire container */
    background-position: center !important;
    /* Centers the image */
    width: 100%;
    // margin-top: 30px;
    // margin-bottom: 100px ;
    height: 248px;
    padding: 20px;

    .row {
      padding: 15px 0px;
      // border-bottom: 1px solid #282828;
    }

    background: #111;
    border-radius: 10px;

    border-radius: 15px;
    background: #E8F2FF;

    .domainMain {
      align-items: center;
      overflow: auto;

      .prize_div {
        position: absolute;
        bottom: 50px;
        /* Distance from the bottom of the page */
        left: 34px;

        h2 {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 15.385px;
          /* 85.473% */
        }

        .innner_prize {
          display: flex;
          align-items: center;
          gap: 5px;

          h3 {
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 15.385px;
            /* 76.926% */
            padding-top: 10px;
          }
        }
      }

      &::-webkit-scrollbar {
        display: none !important;
      }

      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -62%);
        display: flex;
        justify-content: center;
        max-width: 800px;
        // overflow: auto;
        color: #000;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 15.385px; /* 85.473% */

        /* 48px */
        &::-webkit-scrollbar {
          display: none !important;
        }

        span {
          color: #000;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 15.385px; /* 85.473% */
        }
      }
    }

    .availtick {
      color: #00ff5e !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 18px */
      padding: 5px 20px;
      width: 86px;
      display: none;
      justify-content: end;
      top: 20px;
      right: 20px;
      position: absolute;
      border-radius: 50px;
      background: rgba(0, 255, 94, 0.12);

      h5 {
        color: #00ff5e;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 18px */
      }
    }

    .domainRightHeartDiv {
      align-items: center;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;

      p {
        font-weight: 500;
        font-style: normal;
        font-size: 16px;
        color: #fff;
      }

      .cartButton {
        color: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        /* 13.2px */
        text-transform: capitalize;
        padding: 16px 20px;
        border-radius: 12px;
        background: #222D37;
        outline: none !important;
        border: none;
        width: 117.5px !important;

        &:hover {
          // background: #FF0083 !important;
          // color: #fff;
          // border: unset !important;

        }

        img {
          margin-right: 12px;
        }
      }

      .hImg {
        margin: 0px 20px;
      }
    }

    .domainRightHeartDiv:hover {
      opacity: 1;
      transition: 0.3s;
    }

    .auction {
      h6 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        text-transform: uppercase;
        color: #c9c9c9;
        margin-bottom: 7px;
      }

      h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        text-transform: uppercase;
        color: #1d1d1d;
      }
    }
  }

  .availableDomain-trans {
    margin-top: 30px;

    // margin-bottom: 100px !important;
    .row {
      padding: 15px 0px;
      // border-bottom: 1px solid #efefef;
    }

    // background: #fafafa;
    // border: 1px solid #efefef;
    // box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
    border-radius: 10px;

    .domainMain {
      align-items: center;

      p {
        // margin-right: 20px;

        span {
          font-weight: 700;
          font-style: normal;
          font-weight: 700;
          color: #292929;
        }
      }
    }

    .availtick {
      background: #36ab61;
      border-radius: 45px;
      padding: 7.5px 13.5px;
      align-items: center;
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      color: #ffffff;

      img {
        margin-right: 4px;
      }
    }

    .domainRightHeartDiv {
      align-items: center;

      p {
        font-weight: 500;
        font-style: normal;
        font-size: 16px;
        color: #5a5a5a;
      }

      .cartButton {
        background: #ff0083;
        border-radius: 5px;
        padding: 13px 13px !important;
        font-weight: 600;
        font-style: normal;
        font-size: 16px;
        color: #ffffff;
        outline: none !important;
        border: none;

        img {
          margin-right: 12px;
        }
      }

      .hImg {
        margin: 0px 20px;
      }
    }

    .auction {
      h6 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        text-transform: uppercase;
        color: #c9c9c9;
        margin-bottom: 7px;
      }

      h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        text-transform: uppercase;
        color: #1d1d1d;
      }
    }
  }

  .navigation {
    margin-top: 20px;
  }

  .pagination {
    justify-content: center;

    .page-link {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      color: #9d9d9d;
      border: none;
      background-color: transparent;

      &.active {
        background-color: #ff0083;
        border-radius: 5px;
        color: #fff;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .suggestedDomains {
    margin-top: 100px;
  }

  .availableDomain-suggested {
    margin-bottom: 100px;
  }

  .addedSuccess {
    background: rgba(54, 171, 97, 0.2);
    border-radius: 35px;
    padding: 13px 21px;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    color: #36ab61;
    outline: none !important;
    border: none;

    img {
      margin-right: 12px;
    }
  }

  .inline-btns {
    /* margin-bottom: 40px; */
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;

    // height: 50px;
    /* padding-top: 9px; */
    &::-webkit-scrollbar {
      display: none;
      /* Safari and Chrome */
    }

    a {
      background: #ffffff;
      border: 1px solid #efefef;
      border-radius: 6px;
      padding: 12.5px 49.25px;
      margin-right: 8px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color: #868686;
      transition: 0.7s;
      white-space: nowrap;

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        cursor: pointer;

        &:hover {
          // background-color: #ff0083;
          color: #ffffff;
          cursor: pointer;
        }
      }

      &:hover {
        // background-color: #ff0083;
        color: #868686;
        // cursor: not-allowed;
      }
    }

    .activeBtn {
      background: #ffffff;
      border: 1px solid #efefef;
      border-radius: 6px;
      padding: 12.5px 49.25px;
      margin-right: 8px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color: #868686;
      transition: 0.7s;

      &:last-child {
        margin-right: 0;
      }

      background-color: #ff0083;
      color: #fff;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .pink-text {
    color: #ff0083;
  }

  .cartButton2 {
    background: transparent !important;
    border: 1px solid #ff0083 !important;
    border-radius: 5px !important;
    color: #ff0083 !important;
  }

  .add-cart-btn {
    border-radius: 15px;
    background: #DFEAFC;
    width: 44px;
    height: 44px;
    padding: 11px 10.72px 11px 11.28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
  }

  .add-cart-btn:active {
    // background-color: #ff0083;
    // border: 1px solid #ff0083;
    // transition: 0.3s;
  }

  .add-cart-btn:active svg path {
    // fill: #fff;
  }


  .remove-cart-btn {
    border-radius: 5px;
    border: 1px solid #ff5042;
    background: #fff;
    padding: 11px;
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
  }
}

.svgtomiilogo {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1199px) {
  .domain-search .domainss {
    color: #0E1120;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
  }

  .domain-search .inline-btns {
    /* margin-bottom: 40px; */
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    // height: 50px;
    /* padding-top: 9px; */
    padding-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 991px) {
  .myDomain .my-navs-one {
    margin-top: 40px;
    margin-right: 15px;
    margin-left: 15px;
    grid-gap: 12px;
    gap: 12px;
    display: grid;
    gap: 20px;
    grid-template-columns: 2fr 2fr;
  }

  .pspans {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    margin-bottom: 48px;
    overflow-y: auto;
  }

  .upper_domainnss .btttttt {
    color: #0E1120;
    font-size: 40px !important;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
  }

  .card-manage .partnerimg {
    position: absolute;
    top: 0px;
    width: 100%;
    right: 0px;
    z-index: -1;
  }

  .domain-search .domainss {
    color: #0E1120;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
  }

  .domain-search .progress_mains {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }

  .myyyelipse2 {
    display: none !important;
  }

  .domain-search .domaincardlarge {
    width: 100% !important;
    height: 500px !important;
    padding: 50px 34px !important;
    margin: 0 auto;
  }

  .progress-sec .btn-bar {
    width: 100% !important;
    margin: 0 auto;
    margin-top: 40px;
  }

  .domain-search .top_domainss {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    gap: 20px;
  }

  .domain-search .input-field .mysearchinput .crosImg2 {
    display: none;
  }

  .domain-search .inline-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .domain-search .input-field .mysearchinput .crosImg {
    right: 230px;
  }
}

@media (max-width: 601px) {
  .domain-search {
    min-height: 20vh;
  }

  .domain-search .progress_mains .top_tab_data p {
    color: #5d5a5b;
    padding-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 8px;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .domain-search .actualResultDiv .artabs-navs .tabb {
    width: 50%;
    padding: 20px 0px;
    text-align: center;
    border-left: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
  }

  .domain-search .availableDomain .domainMain {
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .domain-search .availableDomain .domainRightHeartDiv {
    align-items: center;
    justify-content: flex-start;
  }

  .domain-search .actualResultDiv p {
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    margin-bottom: 10px;
  }

  .domain-search .availableDomain .availtick2 {
    background: #36ab61;
    border-radius: 45px;
    padding: 7.5px 0px;
    align-items: center;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    color: #ffffff;
  }

  .domain-search .availableDomain .domainRightHeartDiv .cartButton {
    // padding: 8px 0px;
    white-space: nowrap;
  }

  .domain-search .availableDomain {
    // margin-top: 30px;
    // margin-bottom: 53px !important;
  }

  .domain-search .add-cart-btn {
    width: 100%;
  }
}

.banner-bg777 {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
}

.banner-bg55 {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 796px;
  object-fit: cover;
}

.cPointer {
  cursor: pointer;
  color: #fff;
  border-radius: 15px;
  width: 114px;
  background: unset;
  padding: 13px 40px;
  color: #222D37;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 21px */
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  /* 16px */
}

// all modal scss here..........

.mint-modal {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2.5px);

  .modal-dialog {
    max-width: 414px;
  }

  .modal-content {
    background: #111111;
    border-radius: 10px;
  }

  .modal-header {
    justify-content: center;
    padding: 0;
    padding: 20px 30px;
    background: #111111;
    border: none;
  }

  .modal-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
  }

  .modal-body {
    padding: 20px 30px;
  }

  .btn-close {
    position: absolute;
    top: 18px;
    right: 15px;
    opacity: 1;
    background: url("../../../src/Assests/entypo_cross.svg") !important;
    border: none;
    padding: 9px;
    z-index: 999;
    background-repeat: no-repeat !important;
    padding: 0px;
    margin: 0px;
    background-position: center;
    background-size: cover !important;
    width: 28px;
    height: 28px;
  }

  .inner-content {
    .text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 2px solid #2d2d2d;

      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        color: #fff;
      }

      h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        color: #fff;
        display: flex;
        align-items: center;
      }
    }

    .text1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
      margin-bottom: 30px;

      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 170%;
        color: #fff;
      }

      h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: #fff;
      }
    }
  }

  .register-btn {
    background: #ff0083;
    box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
    border-radius: 5px;
    padding: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff !important;
    text-align: center;
    display: block;
    width: 100%;
  }

  .inner-content-minting {
    img {
      display: block;
      margin: 0 auto;
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 100%;
      text-align: center;
      text-transform: uppercase;
      color: #292929;
      margin-top: 20px;
    }

    h6 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      text-align: center;
      color: #616161;
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }
}

.domain-search .availableDomain .domainRightHeartDiv .cartButton {
  width: 200px;
  justify-content: center;
}

.domain-search .transparent {
  background: #ffffff !important;
  border: 1.5px solid #ff0083 !important;
  border-radius: 5px !important;
  font-style: normal !important;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 100% !important;
  color: #ff0083 !important;
}

@media (max-width: 600px) {
  .domain-search .availableDomain .domainRightHeartDiv {
    // flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px 0;
  }

  .domain-search .availableDomain .auction h5 {
    display: flex;
    align-items: center;
  }

  .domain-search .availableDomain-suggested {
    margin-bottom: 40px;
  }

  .myDomain .domainSearh .sImg {
    width: 30px;
  }
}

@media (max-width: 500px) {
  .domain-search .input-field .mysearchinput input {
    width: 250px !important;
  }
}

.modal-cancel-btn {
  padding: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #fff !important;
  text-align: center;
  display: block;
  width: 200px;
  margin: 0 auto;
  border-radius: 5px;

  &:hover {
    background: #ff0083;
    box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
    border-radius: 5px;
    color: #ffffff !important;
  }
}

// new scss mobile here........................

@media (max-width: 600px) {
  .showsearch-icon {
    display: block !important;
    position: absolute;
    z-index: 999;
  }

  .domain-search .input-field .mysearchinput input {
    width: 100%;
  }

  .domain-search .input-field .btn-searchbar span {
    display: none;
  }

  .domain-search .input-field .btn-searchbar {
    width: 53px !important;
    height: 48px !important;
    padding: 17px !important;
  }

  .domain-search .input-field input {
    // height: 82px !important;
    // padding-left: 52px !important;
  }

  .domain-search .input-field .crosImg {
    right: 80px !important;
  }

  .domain-search .availableDomain .domainRightHeartDiv .cartButton {
    width: 100% !important;
    max-width: 100% !important;
  }

  .domain-search .availableDomain .domainMain {
    justify-content: space-between;
  }

  .domain-search .availableDomain .availtick {
    padding: 6px 12px;
    justify-content: center;
  }

  .domain-search .availableDomain .availtick p {
    margin: 0 auto;
  }

  .myDomain .my-navs-one {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: visible;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}