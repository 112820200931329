.main-footer {
    // border-top: 1px solid #282828;
    // background: var(--bg-100, #111);
    background: #E7F2FF;
    z-index: 99;
    position: relative;
padding: 0px 0px;
overflow: hidden;
.main-footer a:hover {
    color: #ff0083 !important;
}
.sociallink:hover {
    color: #FF0083 !important;
  }
  
.fottershade{
    position: absolute;
    bottom: 0px;
    right: 0;
    
}
    .footertop {
        // padding: 48px 0px 73px;
        // display: flex;
        // justify-content: space-between;
        // align-items: flex-end;
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        .topleft {
            .topleftimg {
                margin-bottom: 19px;
            }

            .topleftpara {
                color: var(--White, var(--White, #FFF));
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
            }
        }

        .topright {
            display: flex;
            justify-content:flex-start;
            align-items: center;
            gap: 8px;
            margin-top: 80px;

            // .sociallink {
            //     display: flex;
            //     width: 32px;
            //     height: 32px;
            //     padding: 7.529px;
            //     justify-content: center;
            //     align-items: center;
            //     border-radius: 170px;
            //     background: rgba(40, 40, 40, 0.20);
            //     backdrop-filter: blur(10px);
            //     text-decoration: none !important;
            // }
        }
    }

    .footermid {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 73px;

        .footermidright {
            .midinner {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                .midhead {
                    color: var(--White, var(--White, #FFF));
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%;
                    margin-bottom: 26px;
                }

                .midpara {
                    color: var(--White, #FFF);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    margin-bottom: 12px;
                }
            }
        }

        .footermidleft {
            display: flex;
            align-items: flex-start;
            max-width: 870px;
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-between;

            .midinner {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                .midhead {
                    color: var(--systemGrey-900, #212121);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;
                    margin-bottom: 15px;
                }

                .midpara {
                    color: var(--systemGrey-900, #212121);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    margin-bottom: 15px;
                }
            }
        }
    }

    .footerlast {
        color: var(--Accent, #FF0083);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        display: flex;
        padding: 24px 0px;
        justify-content: center;
        align-items: center;
        position: relative;

        .footerlastline {
            border: 1px solid var(--systemGrey-900, #212121);
opacity: 0.06;
background: var(--systemGrey-900, #212121);
            position: absolute;
            height: 1px;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
          margin-top: 40px;
        }
        .endpara{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 18px;
        padding-top: 150px;
        .paraend{
            color: var(--systemGrey-900, #212121);
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
        }
        }
    }
}
.footer_data{
    padding: 50px 120px;
    
}

@media(max-width:992px) {
    .footor {
        position: absolute;
        bottom: 113px !important;
        right: 0px;
    }
    .footer_data {
        padding: 0px;
    }
    .main-footer .footermid {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 30px 20px;
    }

    .main-footer .footertop {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;

        .topleft {
            justify-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
        }
    }
    .main-footer .footermid .footermidleft .midinner{
        width: 50%;
    }
    .main-footer .footermid .footermidleft{
        gap: 30px 0px;
    }
    .sdhgavschjBcasjkbf h4{
        font-size: 18px !important;
    }
    .main-footer .footerlast .endpara{
        padding-top: 65px;
    }
    section.tokenstaking{
        padding-top: 30px;
    }
    section.tokenstaking{
        margin-bottom: 30px !important;
    }

}



.footermidleft {
    display: flex;
    align-items: flex-start;
    max-width: 794px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    .midinner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .midhead {
            color: var(--systemGrey-900, #212121);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 15px;
        }

        .midpara {
            color: var(--systemGrey-900, #212121);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            margin-bottom: 15px;
        }
        .midpara:hover{
            color: #FF0083;        }
    }
}

@media(max-width:600px){
    .main-footer .footerlast .footerlastline{
        margin-top: 0px !important;
    }
    .main-footer .footerlast .endpara{
        padding-top: 0px !important;
        margin-top: 50px;
    }
    .footerlastmbl{
        display: block !important;
    }
    .main-footer .footerlast{
        padding: 0px !important;
    }
    .main-footer{
        padding: 50px 20px;
    }
    .main-footer .fottershade{
        top: 0;
    }
    .footermidleft{
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-width: 100%;
        margin-top: 45px;
    }
    .main-footer .footertop .topleft .topleftimg{
        margin-bottom: 0px;
    }
    .main-footer .footertop .topright{
        margin-top: 35px;
    }
    .main-footer .footertop{
        gap: 0px;
    }
    .lastbtn{
        display: block !important;
        margin: 45px 0px;
        .entbtnsss{
    
            border-radius: 15px;
    background: #222D37;
    width: 243px;
    margin: 0 auto;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E8F2FF;
    
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
        }
    }
 
}
.footor{
    position: absolute;
   bottom: -100px;
   right: 0px;
   pointer-events: none;
}
.sociallink svg {
    &:hover{
    circle{
    stroke: #FF0083 !important;
    }
    path {
    fill: #FF0083 !important;
    }
    }
        }
