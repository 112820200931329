.cartMain {
    .add-cart-btn{
        border-radius: 5px;
        border: 1px solid #FFDBED;
        background: #FFF;
        padding: 11px;
        width: 46px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 14px;
    }
}
.cartUnAvail{
    max-width: 76px !important;
    background: red !important;
}

@media(max-width:600px){
    .cart-modal .modal-body .cart-main-modal .cart-items .cart-item {
        padding: 9px !important;
    }
    .cart-modal .modal-body .cart-main-modal .cart-items .cart-item .right .inner-text h6 {
        font-size: 13px !important;
    }
    .cart-modal .modal-body .cart-main-modal .cart-items .cart-item .left .inner-right h6 {
        font-size: 14px !important;
    }
    .cart-modal .modal-body .cart-main-modal .cart-items .cart-item .left .inner-right p {
        font-size: 9px !important;
        padding: 2px 5px !important;
        border-radius: 30px !important;
    }
    .cart-modal .modal-body {
        padding: 30px 17px !important;
        padding-top: 10px !important;
    }
}